import { Injectable } from '@angular/core';
import {
  ApiClient,
  CallBuilder,
  CallMethod,
  Resource,
  URLS,
} from '@codingninjas/networking';
import { Observable } from 'rxjs';
import { MentorUserResponse } from './+state/mentor/models/mentor-user.response';
import { DoubtResponse } from './+state/doubt/models/doubt-response';
import { MentorState } from './+state/mentor/reducers/mentor.reducer';
import { DoubtsResponse } from './+state/doubt/models/doubts-response';
import { RxSocket, SocketsService } from '@codingninjas/sockets';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { AssignedDoubtResponse } from './+state/doubt/models/assigned-doubt-response';
import { UsersResponse } from './+state/doubt/models/users-response';
import { CourseModulesResponse } from './+state/doubt/models/course-modules-response';
import { MentorUser } from './+state/mentor/models/mentor-user';
import { takeWhile } from 'rxjs/operators';
import { isNullOrUndefined } from '@codingninjas/ninjas-utils';
import { AvailableDoubtsCountResponse } from './+state/doubt/models/available-doubts-count-response';
import { CourseModuleDoubtStatsResponse } from './+state/doubt/models/course-module-doubt-stats.response';
import {
  DoubtRejectCount,
  DoubtTargetResponse,
} from './+state/doubt/models/doubt-target-module-response';
import { CourseModuleTaResponse } from './+state/doubt/models/course-module-ta-response';
import { SingleDoubtDetailsResponse } from './+state/doubt/models/single-doubt-details-response';
import { ProjectEvaluationTargetResponse } from './+state/doubt/models/project-evaluation-target.response';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  MonthlyShareableCertificateCtaResponse,
  MonthlyShareableCertificateResponse,
} from './models/monthly_shareable_certificate.response';

@Injectable({
  providedIn: 'root',
})
export class CoreService {
  mentorsRxSocket: RxSocket;
  userRxSocket: RxSocket;
  mentorUser: MentorUser;

  constructor(
    private router: Router,
    private socketsService: SocketsService,
    private apiClient: ApiClient,
    private _mentorStore: Store<MentorState>,
    private mentorStore: Store<MentorState>,
    private http: HttpClient
  ) {
    this.mentorStore
      .pipe(
        select((state) => state.mentor.mentorUser),
        takeWhile(() => isNullOrUndefined(this.mentorUser))
      )
      .subscribe((mentorUser) => {
        if (mentorUser) {
          this.setMentorUser(mentorUser);
          this.setMentorSocket(mentorUser.node_access_token);
        }
      });
  }

  setMentorUser(user) {
    this.mentorUser = user;
  }

  userHasRole(role) {
    if (this.mentorUser) {
      return this.mentorUser.roles.includes(role);
    } else {
      return false;
    }
  }

  getIncompleteContentCount() {
    return this.mentorUser.incomplete_content_count;
  }

  setMentorSocket(node_access_token) {
    const nodeQueryString = 'node_access_token=' + node_access_token;
    this.mentorsRxSocket = this.socketsService.getSocket(
      environment.baseNodeUrl + 'employees',
      nodeQueryString
    );
  }

  getMentorSocket() {
    return this.mentorsRxSocket;
  }

  setUserSocket(node_access_token) {
    const nodeQueryString = 'node_access_token=' + node_access_token;
    this.userRxSocket = this.socketsService.getSocket(
      environment.baseNodeUrl + URLS.V4.NINJAS_IO.USER_SOCKET,
      nodeQueryString
    );
  }

  getUserSocket() {
    return this.userRxSocket;
  }

  getMentorUser() {
    return this.mentorUser;
  }

  fetchMentorUser(): Observable<Resource<MentorUserResponse>> {
    const call = new CallBuilder(
      CallMethod.Get,
      URLS.V3.MENTORS.FETCH_MENTOR_USER
    ).build();
    return this.apiClient.enqueue<MentorUserResponse>(call);
  }

  getMentorCourseModules(): Observable<Resource<CourseModulesResponse>> {
    const call = new CallBuilder(
      CallMethod.Get,
      URLS.V3.MENTORS.COURSE_MODULES
    ).build();
    return this.apiClient.enqueue<CourseModulesResponse>(call);
  }

  fetchDoubtModuleUsers(moduleId): Observable<Resource<UsersResponse>> {
    const call = new CallBuilder(
      CallMethod.Get,
      URLS.V3.MENTORS.MODULE_DOUBT_USERS
    )
      .setParam('module_id', moduleId)
      .build();
    return this.apiClient.enqueue<UsersResponse>(call);
  }

  fetchAllDoubtsOfUser(moduleId, userId): Observable<Resource<DoubtsResponse>> {
    const call = new CallBuilder(
      CallMethod.Get,
      URLS.V3.MENTORS.ALL_DOUBTS_OF_USER
    )
      .setParam('module_id', moduleId)
      .setParam('user_id', userId)
      .build();
    return this.apiClient.enqueue<DoubtsResponse>(call);
  }

  updateDoubtBasePriority(
    doubtId,
    basePriorityValue
  ): Observable<Resource<DoubtResponse>> {
    const call = new CallBuilder(
      CallMethod.Post,
      URLS.V3.MENTORS.UPDATE_DOUBT_BASE_PRIORITY
    )
      .setParam('base_priority', basePriorityValue)
      .setParam('doubt_id', doubtId)
      .build();
    return this.apiClient.enqueue<DoubtResponse>(call);
  }

  submitFeedbackRating(
    doubtId,
    feedback,
    originalRating,
    selectedOption
  ): Observable<Resource<DoubtResponse>> {
    const call = new CallBuilder(
      CallMethod.Post,
      URLS.V3.MENTORS.DOUBT_STUDENT_FEEDBACK
    )
      .setParam('feedback', feedback)
      .setParam('doubt_id', doubtId)
      .setParam('rating', originalRating)
      .setParam('rating_reason', selectedOption)
      .build();
    return this.apiClient.enqueue<DoubtResponse>(call);
  }

  assignDoubt(
    onlineMode: boolean = false
  ): Observable<Resource<AssignedDoubtResponse>> {
    const call = new CallBuilder(CallMethod.Post, URLS.V3.MENTORS.ASSIGN_DOUBT)
      .setParam('online_mode', onlineMode.toString())
      .build();
    return this.apiClient.enqueue<AssignedDoubtResponse>(call);
  }

  fetchActiveDoubts(): Observable<Resource<DoubtsResponse>> {
    const call = new CallBuilder(
      CallMethod.Get,
      URLS.V3.MENTORS.ACTIVE_DOUBTS
    ).build();
    return this.apiClient.enqueue<DoubtsResponse>(call);
  }

  fetchDoubtCallStats(): Observable<Resource<any>> {
    const call = new CallBuilder(
      CallMethod.Get,
      URLS.V3.MENTORS.DOUBT_CALL_STATS
    ).build();
    return this.apiClient.enqueue<any>(call);
  }

  acceptDoubt(doubtId): Observable<Resource<DoubtResponse>> {
    const call = new CallBuilder(CallMethod.Post, URLS.V3.MENTORS.ACCEPT_DOUBT)
      .setParam('doubt_id', doubtId)
      .build();
    return this.apiClient.enqueue<DoubtResponse>(call);
  }

  rejectDoubt(doubtId, reason): Observable<Resource<DoubtResponse>> {
    const call = new CallBuilder(CallMethod.Post, URLS.V3.MENTORS.REJECT_DOUBT)
      .setParam('doubt_id', doubtId)
      .setParam('reason', reason)
      .build();
    return this.apiClient.enqueue<DoubtResponse>(call);
  }

  unassignDoubt(doubtId): Observable<Resource<DoubtResponse>> {
    const call = new CallBuilder(
      CallMethod.Post,
      URLS.V3.MENTORS.UNASSIGN_DOUBT
    )
      .setParam('doubt_id', doubtId)
      .build();
    return this.apiClient.enqueue<DoubtResponse>(call);
  }

  assignAndAcceptDoubt(doubtId): Observable<Resource<DoubtResponse>> {
    const call = new CallBuilder(
      CallMethod.Post,
      URLS.V3.MENTORS.ASSIGN_AND_ACCEPT_DOUBT
    )
      .setParam('doubt_id', doubtId)
      .build();
    return this.apiClient.enqueue<DoubtResponse>(call);
  }

  fetchResolvedDoubts(
    limit: number,
    offset: number,
    from: string,
    to: string
  ): Observable<Resource<DoubtsResponse>> {
    const call = new CallBuilder(
      CallMethod.Get,
      URLS.V3.MENTORS.RESOLVED_DOUBTS
    )
      .setParam('limit', limit.toString())
      .setParam('offset', offset.toString())
      .setParam('from', from)
      .setParam('to', to)
      .build();
    return this.apiClient.enqueue<DoubtsResponse>(call);
  }

  fetchPendingOnUserDoubts(
    limit: number,
    offset: number
  ): Observable<Resource<DoubtsResponse>> {
    const call = new CallBuilder(
      CallMethod.Get,
      URLS.V3.MENTORS.PENDING_ON_USER_DOUBTS
    )
      .setParam('limit', limit.toString())
      .setParam('offset', offset.toString())
      .build();
    return this.apiClient.enqueue<DoubtsResponse>(call);
  }

  fetchReviewPendingDoubts(
    limit: number,
    offset: number
  ): Observable<Resource<DoubtsResponse>> {
    const call = new CallBuilder(
      CallMethod.Get,
      URLS.V3.MENTORS.REVIEW_PENDING_DOUBTS
    )
      .setParam('limit', limit.toString())
      .setParam('offset', offset.toString())
      .build();
    return this.apiClient.enqueue<DoubtsResponse>(call);
  }

  fetchAvailableDoubts(
    limit: number,
    offset: number,
    courseModuleId: number,
    createdAtFilter: string,
    selectedDoubtId: number
  ): Observable<Resource<DoubtsResponse>> {
    const call = new CallBuilder(
      CallMethod.Get,
      URLS.V3.MENTORS.AVAILABLE_DOUBTS
    )
      .setParam('limit', limit.toString())
      .setParam('offset', offset.toString())
      .setParam('course_module_id', courseModuleId.toString())
      .setParam('time_filter', createdAtFilter)
      .setParam('doubt_id', selectedDoubtId.toString())
      .build();
    return this.apiClient.enqueue<DoubtsResponse>(call);
  }

  fetchAvailableDoubtsCount(
    onlineMode: boolean
  ): Observable<Resource<AvailableDoubtsCountResponse>> {
    const call = new CallBuilder(
      CallMethod.Get,
      URLS.V3.MENTORS.AVAILABLE_DOUBTS_COUNT
    )
      .setParam('online_mode', onlineMode.toString())
      .build();
    return this.apiClient.enqueue<AvailableDoubtsCountResponse>(call);
  }

  fetchCourseModuleTAs(
    courseModuleID
  ): Observable<Resource<CourseModuleTaResponse>> {
    const call = new CallBuilder(
      CallMethod.Get,
      URLS.V3.MENTORS.COURSE_MODULE_TA
    )
      .setParam('course_module_id', courseModuleID.toString())
      .build();
    return this.apiClient.enqueue<CourseModuleTaResponse>(call);
  }

  fetchAllCustomAssignedDoubts(): Observable<Resource<DoubtsResponse>> {
    const call = new CallBuilder(
      CallMethod.Get,
      URLS.V3.MENTORS.ALL_CUSTOM_ASSIGNED_DOUBTS
    ).build();
    return this.apiClient.enqueue<DoubtsResponse>(call);
  }

  customAssignDoubtToMentor(
    mentorId,
    doubtId
  ): Observable<Resource<DoubtResponse>> {
    if (!isNullOrUndefined(mentorId)) {
      mentorId = mentorId.toString();
    }
    const call = new CallBuilder(
      CallMethod.Post,
      URLS.V3.MENTORS.CUSTOM_ASSIGN_DOUBT_TO_MENTOR
    )
      .setParam('mentor_id', mentorId)
      .setParam('doubt_id', doubtId.toString())
      .build();
    return this.apiClient.enqueue<DoubtResponse>(call);
  }

  fetchCustomAssignedDoubts(): Observable<Resource<DoubtsResponse>> {
    const call = new CallBuilder(
      CallMethod.Get,
      URLS.V3.MENTORS.CUSTOM_ASSIGNED_DOUBTS
    ).build();
    return this.apiClient.enqueue<DoubtsResponse>(call);
  }

  fetchAllActiveDoubts(): Observable<Resource<DoubtsResponse>> {
    const call = new CallBuilder(
      CallMethod.Get,
      URLS.V3.MENTORS.ALL_TA_ACTIVE_DOUBTS
    ).build();
    return this.apiClient.enqueue<DoubtsResponse>(call);
  }

  fetchDoubtTarget(): Observable<Resource<DoubtTargetResponse>> {
    const call = new CallBuilder(
      CallMethod.Get,
      URLS.V3.MENTORS.FETCH_DAILY_TARGET
    ).build();
    return this.apiClient.enqueue<DoubtTargetResponse>(call);
  }

  fetchDoubtRejectCount(): Observable<Resource<DoubtRejectCount>> {
    const call = new CallBuilder(
      CallMethod.Get,
      URLS.V3.MENTORS.FETCH_DOUBT_REJECT_COUNT
    ).build();
    return this.apiClient.enqueue<DoubtRejectCount>(call);
  }

  fetchSingleDoubtDetails(
    doubtId: number
  ): Observable<Resource<SingleDoubtDetailsResponse>> {
    const call = new CallBuilder(
      CallMethod.Get,
      URLS.V3.MENTORS.FETCH_SINGLE_DOUBT_DETAILS
    )
      .setParam('doubt_id', doubtId.toString())
      .build();
    return this.apiClient.enqueue<SingleDoubtDetailsResponse>(call);
  }

  saveInviteLinkClick(workspaceId: number): Observable<Resource<any>> {
    const body = { workspace_id: workspaceId, source: 'mentor' };
    const call = new CallBuilder(
      CallMethod.Post,
      URLS.V3.MENTORS.SLACK_INVITE_LINK_CLICKED
    )
      .body(body)
      .build();
    return this.apiClient.enqueue<any>(call);
  }

  fetchMentorSlackGroupDataActive(): Observable<Resource<any>> {
    const call = new CallBuilder(
      CallMethod.Get,
      URLS.V3.MENTORS.GET_MENTOR_SLACK_DATA
    ).build();
    return this.apiClient.enqueue<any>(call);
  }

  changeOnlineStatus(status = true): Observable<Resource<any>> {
    const call = new CallBuilder(
      CallMethod.Post,
      URLS.V3.MENTORS.MARK_DASHBOARD_ONLINE
    ).build();
    return this.apiClient.enqueue<any>(call);
  }

  markTaWorkspaceOnline() {
    const call = new CallBuilder(
      CallMethod.Post,
      URLS.V3.MENTORS.WORKSPACE.TA_WORKSPACE_MARK_ONLINE
    ).build();
    return this.apiClient.enqueue<any>(call);
  }

  markTaWorkspaceOffline() {
    const call = new CallBuilder(
      CallMethod.Post,
      URLS.V3.MENTORS.WORKSPACE.TA_WORKSPACE_MARK_OFFLINE
    ).build();
    return this.apiClient.enqueue<any>(call);
  }

  sendPing() {
    const type = 'ta_workspace';
    const key = type + '_ping';
    const lastPingTime = localStorage.getItem(key);
    if (lastPingTime) {
      const timestamp = Number.parseInt(lastPingTime, 10) || 0;
      if (timestamp > Date.now() - 29 * 1000) {
        return;
      }
    }

    let params = new HttpParams();
    params = params.set('userId', this.mentorUser.id.toString());
    params = params.set('type', type);

    this.http
      .post(
        URLS.PING_URL,
        {
          key: 'HqWMBX8PQP3lVgqBBYxKX21UY0nQU5ru6ORG5aQp',
        },
        {
          params,
        }
      )
      .subscribe(
        () => {
          localStorage.setItem(key, Date.now() + '');
        },
        () => {}
      );
  }

  mentorProjectEvaluationTarget(): Observable<
    Resource<ProjectEvaluationTargetResponse>
  > {
    const call = new CallBuilder(
      CallMethod.Get,
      URLS.V3.MENTORS.GET_MENTOR_PROJECT_EVALUATION_TARGET
    ).build();
    return this.apiClient.enqueue<any>(call);
  }

  createFlag(
    flaggableId: number,
    flaggedById: number,
    flagReason: number,
    flaggableType: string,
    textInputData: string,
    doubtId: number
  ): Observable<Resource<DoubtResponse>> {
    let params = new HttpParams();
    params = params.set('flagged_by_id', flaggedById.toString());
    params = params.set('flag_reason', flagReason.toString());
    params = params.set('flaggable_id', flaggableId.toString());
    params = params.set('flaggable_type', flaggableType.toString());
    params = params.set('description', textInputData.toString());
    params = params.set('doubt_id', doubtId.toString());

    const call = new CallBuilder(
      CallMethod.Post,
      URLS.V3.MENTORS.USER_CREATE_FLAG
    )
      .params(params)
      .build();
    return this.apiClient.enqueue<DoubtResponse>(call);
  }

  fetchMonthlyShareableCertificate(
    source: 'automatic' | 'ctaClick'
  ): Observable<Resource<MonthlyShareableCertificateResponse>> {
    const call = new CallBuilder(
      CallMethod.Get,
      URLS.V3.MENTORS.MONTHLY_SHAREABLE_CERTIFICATE
    )
      .setParam('source', source)
      .build();
    return this.apiClient.enqueue<any>(call);
  }

  showMonthlyCertificteCta(): Observable<
    Resource<MonthlyShareableCertificateCtaResponse>
  > {
    const call = new CallBuilder(
      CallMethod.Get,
      URLS.V3.MENTORS.MONTHLY_SHAREABLE_CERTIFICATE_CTA
    ).build();
    return this.apiClient.enqueue<any>(call);
  }
}
