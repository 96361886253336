import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FuseConfigService } from '../../../../../@fuse/services/config.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { navigation } from '../../../navigation';
import { FuseNavigationService } from '../../../../../@fuse/components/navigation/navigation.service';
import { CoreService } from '../../../core.service';
import { UserRoles } from '../../../+state/mentor/models/mentor-user';
import { select, Store } from '@ngrx/store';
import { MentorUser } from '../../../+state/mentor/models/mentor-user';
import {
  MentorData,
  MentorState,
} from '../../../+state/mentor/reducers/mentor.reducer';

@Component({
  selector: 'mentor-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  // tslint:disable-next-line: use-component-view-encapsulation
  encapsulation: ViewEncapsulation.None,
})
export class MainComponent implements OnInit, OnDestroy {
  fuseConfig: any;
  navigation: any;
  isLoading = false;
  errorReason: string;
  project_present = true;
  mentorUser: MentorUser;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   */
  constructor(
    private _fuseConfigService: FuseConfigService,
    private _fuseNavigationService: FuseNavigationService,
    private coreService: CoreService,
    private _mentorStore: Store<MentorState>
  ) {
    this.navigation = navigation;

    // Set the private defaults
    this._unsubscribeAll = new Subject();

    if (
      this.coreService.userHasRole(UserRoles.TA) ||
      this.coreService.userHasRole(UserRoles.ADMIN) ||
      this.coreService.userHasRole(UserRoles.DOUBTS_TEAM) ||
      this.coreService.userHasRole(UserRoles.DOUBTS_INTERN) ||
      this.coreService.userHasRole(UserRoles.DOUBTS_MANAGER) ||
      this.coreService.userHasRole(UserRoles.CAREER_CAMP_STUD_EXP_TASK_TEAM) ||
      this.coreService.userHasRole(UserRoles.STUD_EXP_TASK_TEAM) ||
      this.coreService.userHasRole(UserRoles.STUDENT_EXPERIENCE_TEAM) ||
      this.coreService.userHasRole(UserRoles.STUDENT_EXP_MANAGER)
    ) {
      if (!this._fuseNavigationService.getNavigationItem('doubts')) {
        this._fuseNavigationService.addNavigationItem(
          {
            id: 'doubts',
            title: 'Doubts',
            type: 'collapsable',
            icon: 'question_answer',
            children: [
              {
                id: 'workplace',
                title: 'Workplace',
                type: 'item',
                url: '/doubts/workplace',
              },
              {
                id: 'custom_assigned',
                title: 'Custom Assigned',
                type: 'item',
                url: '/doubts/custom-assigned',
                openInNewTab: true,
              },
              {
                id: 'resolved',
                title: 'Resolved',
                type: 'item',
                url: '/doubts/resolved',
              },
              {
                id: 'pending',
                title: 'Pending On User',
                type: 'item',
                url: '/doubts/pending',
              },
              {
                id: 'review_pending',
                title: 'Review Pending',
                type: 'item',
                url: '/doubts/review-pending',
              },
            ],
          },
          'applications'
        );
      }

      this.projectPresentStatus();
      // if (
      //   this.project_present === true &&
      //   !this._fuseNavigationService.getNavigationItem('ta-side')
      // ) {
      //   this._fuseNavigationService.addNavigationItem(
      //     {
      //       id: 'ta-side',
      //       title: 'Project Evaluation',
      //       type: 'collapsable',
      //       icon: 'school',
      //       children: [
      //         // {
      //         //   id: 'unevaluated_projects',
      //         //   title: 'Un Evaluated Projects',
      //         //   type: 'item',
      //         //   url: '/project-evaluation/ta',
      //         // },
      //         // {
      //         //   id: 'evaluated-projects',
      //         //   title: 'Evaluated Projects',
      //         //   type: 'item',
      //         //   url: '/project-evaluation/evaluated-projects',
      //         // },
      //         {
      //           id: 'project-card-dashboard',
      //           title: 'Project Card Dashboard',
      //           type: 'item',
      //           url: '/project-evaluation/project-card-dashboard',
      //         },
      //       ],
      //     },
      //     'applications'
      //   );
      // }

      // if (!this._fuseNavigationService.getNavigationItem('ta-side-problem')) {
      //   this._fuseNavigationService.addNavigationItem(
      //     {
      //       id: 'ta-side-problem',
      //       title: 'Problem Evaluation',
      //       type: 'collapsable',
      //       icon: 'school',
      //       children: [
      //         {
      //           id: 'unevaluated_problems',
      //           title: 'Problem Listings',
      //           type: 'item',
      //           url: '/problem-evaluation/problem-listings',
      //         },
      //       ],
      //     },
      //     'applications'
      //   );
      // }
    }

    if (this.coreService.userHasRole(UserRoles.PROJECT_EVALUATOR)) {
      if (!this._fuseNavigationService.getNavigationItem('ta-side')) {
        this._fuseNavigationService.addNavigationItem(
          {
            id: 'ta-side',
            title: 'Project Evaluation',
            type: 'collapsable',
            icon: 'school',
            children: [
              // {
              //   id: 'unevaluated_projects',
              //   title: 'Un Evaluated Projects',
              //   type: 'item',
              //   url: '/project-evaluation/ta',
              // },
              // {
              //   id: 'evaluated-projects',
              //   title: 'Evaluated Projects',
              //   type: 'item',
              //   url: '/project-evaluation/evaluated-projects',
              // },
              {
                id: 'project-card-dashboard',
                title: 'Project Dashboard',
                type: 'item',
                url: '/project-evaluation/project-dashboard',
              },
            ],
          },
          'applications'
        );
      }
    }

    if (this.coreService.userHasRole(UserRoles.TA)) {
      if (
        !this._fuseNavigationService.getNavigationItem('incomplete-contents')
      ) {
        this._fuseNavigationService.addNavigationItem(
          {
            id: 'incomplete-contents',
            title: 'Incomplete Content',
            type: 'item',
            icon: 'question_answer',
            url: '/incomplete-contents',
            badge: {
              title: coreService.getIncompleteContentCount(),
              bg: 'red',
              fg: 'white',
            },
          },
          'applications'
        );
      }
      if (!this._fuseNavigationService.getNavigationItem('calender')) {
        this._fuseNavigationService.addNavigationItem(
          {
            id: 'calendar',
            title: 'Calendar',
            type: 'item',
            icon: 'today',
            url: '/calendar',
          },
          'applications'
        );
      }
      if (!this._fuseNavigationService.getNavigationItem('taship')) {
        this._fuseNavigationService.addNavigationItem(
          {
            id: 'taship',
            title: 'Taship',
            type: 'collapsable',
            icon: 'school',
            children: [
              {
                id: 'taship_certificate',
                title: 'Taship-Records',
                type: 'item',
                url: '/taship',
              },
              {
                id: 'slot_schedule_request',
                title: 'Leave requests',
                type: 'item',
                url: '/slot-schedule/requests',
              },
            ],
          },
          'applications'
        );
      }
    }

    if (
      this.coreService.userHasRole(UserRoles.ADMIN) ||
      this.coreService.userHasRole(UserRoles.DOUBTS_TEAM) ||
      this.coreService.userHasRole(UserRoles.DOUBTS_INTERN) ||
      this.coreService.userHasRole(UserRoles.DOUBTS_MANAGER) ||
      this.coreService.userHasRole(UserRoles.STUDENT_EXPERIENCE_TEAM) ||
      this.coreService.userHasRole(UserRoles.STUDENT_EXP_MANAGER)
    ) {
      if (!this._fuseNavigationService.getNavigationItem('all_doubts')) {
        this._fuseNavigationService.addNavigationItem(
          {
            id: 'all_doubts',
            title: 'All Doubts',
            type: 'item',
            url: '/doubts/all',
          },
          'doubts'
        );
      }

      if (
        !this._fuseNavigationService.getNavigationItem(
          'all_custom_assigned_doubts'
        )
      ) {
        this._fuseNavigationService.addNavigationItem(
          {
            id: 'all_custom_assigned_doubts',
            title: 'All Custom Assigned',
            type: 'item',
            url: '/doubts/all-custom-assigned-doubts',
          },
          'doubts'
        );
      }

      if (!this._fuseNavigationService.getNavigationItem('custom_search')) {
        this._fuseNavigationService.addNavigationItem(
          {
            id: 'custom_search',
            title: 'Custom Search',
            type: 'item',
            url: '/doubts/custom-search',
          },
          'doubts'
        );
      }

      if (!this._fuseNavigationService.getNavigationItem('doubt_flags')) {
        this._fuseNavigationService.addNavigationItem(
          {
            id: 'doubt_flags',
            title: 'Doubt Flags',
            type: 'item',
            url: '/flags',
          },
          'doubts'
        );
      }

      //
      // if(!this._fuseNavigationService.getNavigationItem("project-evaluation-admin")) {
      //   this._fuseNavigationService.addNavigationItem({
      //     id: 'project-evaluation-admin',
      //     title: 'Project Evaluation',
      //     type: 'collapsable',
      //     children : [
      //       {
      //         id   : 'ta-side',
      //         title: 'TA - Side',
      //         type : 'item',
      //         url  : '/project-evaluation/ta'
      //       }
      //     ]
      //   }, 'applications');
      // }

      if (
        !this._fuseNavigationService.getNavigationItem('admin-doubts-stats')
      ) {
        this._fuseNavigationService.addNavigationItem(
          {
            id: 'admin-doubts-stats',
            title: 'Admin Doubts Stats',
            type: 'collapsable',
            children: [
              {
                id: 'course_module_stats',
                title: 'Module Stats',
                type: 'item',
                url: '/stats/course-module-stats',
              },
              {
                id: 'doubt_targets',
                title: 'Doubt Target Stats',
                type: 'item',
                url: '/stats/doubt-target-stats',
              },
              {
                id: 'ta-stats',
                title: 'TA-Stats',
                type: 'item',
                url: '/stats/ta',
              },
              {
                id: 'doubt-stats',
                title: 'Doubt-Stats',
                type: 'item',
                url: '/stats/doubt-stats',
              },
              {
                id: 'course-ta-stats',
                title: 'Slot Missed Stats',
                type: 'item',
                url: '/stats/slot-missed-stats',
              },
            ],
          },
          'applications'
        );
      }

      if (
        !this._fuseNavigationService.getNavigationItem('admin-project-stats')
      ) {
        this._fuseNavigationService.addNavigationItem(
          {
            id: 'admin-project-stats',
            title: 'Admin Projects Stats',
            type: 'collapsable',
            children: [
              {
                id: 'ta-stats',
                title: 'TA Stats',
                type: 'item',
                url: '/project-evaluation/overall-ta-stats/course-module',
              },
            ],
          },
          'applications'
        );
      }

      if (!this._fuseNavigationService.getNavigationItem('career-camp-batch')) {
        this._fuseNavigationService.addNavigationItem(
          {
            id: 'career-camp-batch',
            title: 'Career Camp Batch',
            type: 'collapsable',
            children: [
              {
                id: 'batch-report',
                title: 'Batch Report',
                type: 'item',
                url: '/stats/batch-report-card',
              },
            ],
          },
          'applications'
        );
      }

      if (
        this.coreService.userHasRole(UserRoles.ADMIN) ||
        this.coreService.userHasRole(UserRoles.STUDENT_EXPERIENCE_TEAM) ||
        this.coreService.userHasRole(UserRoles.STUDENT_EXP_MANAGER) ||
        this.coreService.userHasRole(
          UserRoles.CAREER_CAMP_STUD_EXP_TASK_TEAM
        ) ||
        this.coreService.userHasRole(UserRoles.STUD_EXP_TASK_TEAM)
      ) {
        this.navigation.push({
          id: 'personalized-schedule',
          title: 'Personalized Schedule',
          type: 'item',
          icon: 'today',
          url: '/personalized-schedule',
        });
      }

      if (!this._fuseNavigationService.getNavigationItem('ta-operations')) {
        this._fuseNavigationService.addNavigationItem(
          {
            id: 'ta-operations',
            title: 'Ta Operations',
            type: 'collapsable',
            children: [
              {
                id: 'slot-change-request',
                title: 'Slot Change Requests',
                type: 'item',
                url: '/slot-schedule',
              },
            ],
          },
          'applications'
        );
      }

      if (!this._fuseNavigationService.getNavigationItem('employee-tasks')) {
        this._fuseNavigationService.addNavigationItem(
          {
            id: 'employee-tasks',
            title: 'All Tasks',
            type: 'item',
            icon: 'dashboard',
            url: '/tasks/all',
          },
          'applications'
        );
      }

      if (
        !this._fuseNavigationService.getNavigationItem('employee-call-tasks')
      ) {
        this._fuseNavigationService.addNavigationItem(
          {
            id: 'employee-call-tasks',
            title: 'Call Tasks',
            type: 'item',
            icon: 'dashboard',
            url: '/tasks/call_task_list',
          },
          'applications'
        );
      }
    }

    // Adding Whatsapp Io
    // if (
    //   !this._fuseNavigationService.getNavigationItem('whatsapp') &&
    //   (this.coreService.userHasRole(UserRoles.ADMIN) ||
    //     this.coreService.userHasRole(UserRoles.WHATSAPP_MESSAGE_ROLE))
    // ) {
    //   this.navigation.push({
    //     id: 'whatsapp',
    //     title: 'Whatsapp IO',
    //     type: 'collapsable',
    //     url: '/whatsapp',
    //     children: [
    //       {
    //         id: 'whatsapp-select-users',
    //         title: 'Send Template Message',
    //         icon: 'group',
    //         type: 'item',
    //         url: '/whatsapp/select_users',
    //       },
    //       {
    //         id: 'whatsapp-all-messages',
    //         title: 'See All Messages',
    //         icon: 'chat',
    //         type: 'item',
    //         url: '/whatsapp/messages/all',
    //       },
    //       {
    //         id: 'whatsapp-career-camp-messages',
    //         title: 'Career Camp Messages',
    //         icon: 'chat',
    //         type: 'item',
    //         url: '/whatsapp/messages/career_camp',
    //       },
    //     ],
    //   });
    // }
    // else if(!this._fuseNavigationService.getNavigationItem("project-evaluation-ta")) {
    //   this._fuseNavigationService.addNavigationItem({
    //     id: 'project-evaluation-ta',
    //     title: 'Project Evaluation TA',
    //     type: 'item',
    //     icon: 'spellcheck',
    //     url: '/project-evaluation/ta'
    //   }, 'applications');
    // }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to config changes
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.fuseConfig = config;
      });
  }

  projectPresentStatus() {
    this._mentorStore
      .pipe(select('mentor'), takeUntil(this._unsubscribeAll))
      .subscribe((mentorData: MentorData) => {
        if (mentorData) {
          this.mentorUser = mentorData.mentorUser;
          this.project_present = this.mentorUser.project_present;
        }
      });

    if (
      this.project_present === true &&
      !this._fuseNavigationService.getNavigationItem('ta-side')
    ) {
      this._fuseNavigationService.addNavigationItem(
        {
          id: 'ta-side',
          title: 'Project Evaluation',
          type: 'collapsable',
          icon: 'school',
          children: [
            // {
            //   id: 'unevaluated_projects',
            //   title: 'Un Evaluated Projects',
            //   type: 'item',
            //   url: '/project-evaluation/ta',
            // },
            // {
            //   id: 'evaluated-projects',
            //   title: 'Evaluated Projects',
            //   type: 'item',
            //   url: '/project-evaluation/evaluated-projects',
            // },
            {
              id: 'project-card-dashboard',
              title: 'Project Dashboard',
              type: 'item',
              url: '/project-evaluation/project-dashboard',
            },
          ],
        },
        'applications'
      );
    }
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
