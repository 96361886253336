import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mentor-mentors-app',
  templateUrl: './mentors-app.component.html',
  styleUrls: ['./mentors-app.component.css']
})
export class MentorsAppComponent implements OnInit {

  ngOnInit() {}



}
