<mat-toolbar class="p-0 mat-elevation-z1">
  <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">
    <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
      <button
        mat-icon-button
        class="navbar-toggle-button"
        *ngIf="!hiddenNavbar && !rightNavbar"
        (click)="toggleSidebarOpen('navbar')"
        fxHide.gt-md
      >
        <mat-icon class="secondary-text">menu</mat-icon>
      </button>
      <div
        class="toolbar-separator"
        *ngIf="!hiddenNavbar && !rightNavbar"
        fxHide.gt-md
      ></div>
      <div fxLayout="row" fxLayoutAlign="start center" *ngIf="horizontalNavbar">
        <div class="logo ml-16">
          <img class="logo-icon" src="assets/images/logos/fuse.svg" />
        </div>
      </div>
      <mentor-weekoff-leave-apply
        [mentorUser]="mentorUser"
        [isTa]="isTa"
      ></mentor-weekoff-leave-apply>
      <div class="help-btn">
        <div class="flex-row align-items-center help-icon">
          <span (click)="onHelpClick()" class="apply-span cursor-pointer">
            Need Help?
          </span>
        </div>
      </div>
      <div class="help-btn" *ngIf="showMonthlyCta">
        <div class="flex-row align-items-center help-icon">
          <span
            (click)="viewMonthlyAchievements('ctaClick')"
            class="apply-span cursor-pointer"
          >
            View Monthly Achievements
          </span>
        </div>
      </div>
    </div>

    <div
      class=""
      fxFlex="0 1 auto"
      fxLayout="row"
      fxLayoutAlign="start center"
      *ngIf="mentorUser"
    >
      <button mat-button [matMenuTriggerFor]="userMenu" class="user-button">
        <div fxLayout="row" fxLayoutAlign="center center">
          <img class="avatar mr-0 mr-sm-16" [src]="mentorUser.image" />
          <span class="username mr-12" fxHide fxShow.gt-sm>{{
            mentorUser.name
          }}</span>
          <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
        </div>
      </button>

      <mat-menu #userMenu="matMenu" [overlapTrigger]="false">
        <!--<button mat-menu-item>-->
        <!--<mat-icon>account_circle</mat-icon>-->
        <!--<span>My Profile</span>-->
        <!--</button>-->

        <!--<button mat-menu-item class="">-->
        <!--<mat-icon>mail</mat-icon>-->
        <!--<span>Inbox</span>-->
        <!--</button>-->

        <button mat-menu-item class="" (click)="logout()">
          <mat-icon>exit_to_app</mat-icon>
          <span>Logout</span>
        </button>
      </mat-menu>

      <div class="toolbar-separator" fxHide fxShow.gt-xs></div>

      <div
        class="toolbar-separator"
        *ngIf="!hiddenNavbar && rightNavbar"
        fxHide
        fxShow.gt-xs
      ></div>

      <button
        mat-icon-button
        class="navbar-toggle-button"
        *ngIf="!hiddenNavbar && rightNavbar"
        (click)="toggleSidebarOpen('navbar')"
        fxHide.gt-md
      >
        <mat-icon class="secondary-text">menu</mat-icon>
      </button>
    </div>
  </div>
</mat-toolbar>
