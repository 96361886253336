import { Injectable, ViewContainerRef } from '@angular/core';
import { CoreService } from '../../core/core.service';
import { select, Store } from '@ngrx/store';
import { isNullOrUndefined } from '@codingninjas/ninjas-utils';
import { DoubtState } from '../../core/+state/doubt/reducers/doubt.reducer';
import {
  AddToActiveDoubts,
  AddToAvailableDoubts,
  AddToPendingDoubts,
  AddToResolvedDoubts,
  AddToReviewPendingDoubts,
  DoubtAssigned,
  RemoveAssignedDoubt,
  RemoveFromActiveDoubts,
  RemoveFromAvailableDoubts,
  RemoveFromPendingDoubts,
  RemoveFromResolvedDoubts,
  RemoveFromReviewPendingDoubts,
  UpdateDoubtActivityStatus,
  UpdateDoubtObject,
} from '../../core/+state/doubt/actions/doubt.actions';
import { DoubtStates } from '../../core/+state/doubt/models/doubt';
import { doubtSelectors } from '../../core/+state/doubt/selectors/doubt.selectors';
import { DoubtRejectReasonModalComponent } from '../components/doubt-reject-reason-modal/doubt-reject-reason-modal.component';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DoubtService {
  DOUBT_ACTIVITY_STATUS_KEY: 'doubt_activity_status';
  dialogRef: MatDialogRef<any>;
  slackData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public slackDataObservable: Observable<any> = this.slackData.asObservable();

  constructor(
    private coreService: CoreService,
    private _doubtStore: Store<DoubtState>,
    public dialog: MatDialog
  ) {
    this.listenForDoubtStateChange();
  }

  static checkListChange(previousState, currentState) {
    const listChangeArr = [
      DoubtStates.AVAILABLE,
      DoubtStates.ACTIVE,
      DoubtStates.RESOLVED,
      DoubtStates.PENDING_ON_USER,
    ];
    return (
      listChangeArr.includes(previousState) ||
      listChangeArr.includes(currentState)
    );
  }

  static checkAssignmentChange(previousState, currentState) {
    return (
      DoubtStates.ASSIGNED === previousState ||
      DoubtStates.ASSIGNED === currentState
    );
  }

  initializeDoubtActivityStatus() {
    const doubtActivityStatusFromLs =
      this.getDoubtActivityStatusFromLocalStorage();
    if (doubtActivityStatusFromLs && doubtActivityStatusFromLs === 'true') {
      this._doubtStore.dispatch(new UpdateDoubtActivityStatus(true));
    }
    this._doubtStore
      .pipe(select(doubtSelectors.selectDoubtActivityStatus))
      .subscribe((doubtActivityStatus) => {
        this.setDoubtActivityStatusInLocalStorage(doubtActivityStatus);
      });
  }

  getDoubtActivityStatusFromLocalStorage(): string {
    return localStorage.getItem(this.DOUBT_ACTIVITY_STATUS_KEY);
  }

  setDoubtActivityStatusInLocalStorage(doubtActivityStatus: boolean) {
    localStorage.setItem(
      this.DOUBT_ACTIVITY_STATUS_KEY,
      doubtActivityStatus.toString()
    );
  }

  listenForDoubtStateChange() {
    const mentorsRxSocket = this.coreService.getMentorSocket();
    if (!isNullOrUndefined(mentorsRxSocket)) {
      mentorsRxSocket.listen('doubt_change_event_fire').subscribe((event) => {
        this.handleDoubtChangeEvent(event);
      });
    }
  }

  handleDoubtChangeEvent(event) {
    const doubt = event.doubt;
    this._doubtStore.dispatch(new UpdateDoubtObject(doubt));
    const previousState = event.previous_state;
    const currentState = event.current_state;
    const timer = event.timer;
    const doubtPoint = event.doubt_point;
    const doubtId = parseInt(doubt.id, 10);
    if (DoubtService.checkAssignmentChange(previousState, currentState)) {
      this.handleAssignmentChangeEvent(
        previousState,
        currentState,
        doubtId,
        doubt,
        timer,
        doubtPoint
      );
    } else if (DoubtService.checkListChange(previousState, currentState)) {
      this.handleListChangeEvent(previousState, currentState, doubtId, doubt);
    }
  }

  handleAssignmentChangeEvent(
    previousState,
    currentState,
    doubtId,
    doubt,
    timer,
    doubtPoint
  ) {
    const updateAssignedObject = currentState === DoubtStates.ASSIGNED;
    if (updateAssignedObject) {
      this._doubtStore.dispatch(
        new DoubtAssigned(doubt, timer, null, null, doubtPoint)
      );
      this.handlePreviousStateList(previousState, doubtId);
    } else {
      this._doubtStore.dispatch(new RemoveAssignedDoubt());
      this.handleCurrentStateList(currentState, doubtId);
    }
  }

  handleListChangeEvent(previousState, currentState, doubtId, doubt) {
    this.handlePreviousStateList(previousState, doubtId);
    this.handleCurrentStateList(currentState, doubtId);
  }

  handlePreviousStateList(previousState, doubtId) {
    if (previousState === DoubtStates.ACTIVE) {
      this._doubtStore.dispatch(new RemoveFromActiveDoubts(doubtId));
    } else if (previousState === DoubtStates.RESOLVED) {
      this._doubtStore.dispatch(new RemoveFromResolvedDoubts(doubtId));
    } else if (previousState === DoubtStates.PENDING_ON_USER) {
      this._doubtStore.dispatch(new RemoveFromPendingDoubts(doubtId));
    } else if (previousState === DoubtStates.AVAILABLE) {
      this._doubtStore.dispatch(new RemoveFromAvailableDoubts(doubtId));
    } else if (previousState === DoubtStates.RESOLVED_REVIEW_PENDING) {
      this._doubtStore.dispatch(new RemoveFromReviewPendingDoubts(doubtId));
    }
  }

  handleCurrentStateList(currentState, doubtId) {
    if (currentState === DoubtStates.ACTIVE) {
      this._doubtStore.dispatch(new AddToActiveDoubts(doubtId));
    } else if (currentState === DoubtStates.RESOLVED) {
      this._doubtStore.dispatch(new AddToResolvedDoubts(doubtId));
    } else if (currentState === DoubtStates.PENDING_ON_USER) {
      this._doubtStore.dispatch(new AddToPendingDoubts(doubtId));
    } else if (currentState === DoubtStates.AVAILABLE) {
      this._doubtStore.dispatch(new AddToAvailableDoubts(doubtId));
    } else if (currentState === DoubtStates.RESOLVED_REVIEW_PENDING) {
      this._doubtStore.dispatch(new AddToReviewPendingDoubts(doubtId));
    }
  }

  openRejectModal(config) {
    this.dialogRef = this.dialog.open(DoubtRejectReasonModalComponent, config);
    return this.dialogRef;
  }
}
