import { Injectable } from '@angular/core';
import {
  ApiClient,
  CallBuilder,
  CallMethod,
  Resource,
  URLS,
} from '@codingninjas/networking';
import { Observable } from 'rxjs';
import {
  LeaveCountResponse,
  VitalMetricsResponse,
} from './models/responses/vital-metrics.response';
import { DoubtRatingStatsResponse } from './models/responses/doubt-rating-stats.response';
import { DoubtResolutionStatsResponse } from './models/responses/doubt-resolution-stats.response';
import { DoubtPointsStatsResponse } from './models/responses/doubt-points-stats.response';
import {
  DoubtFlagStatsResponse,
  DoubtMetricsResponse,
} from './models/responses/doubt-metrics.response';
import {
  AssignedStudentsResponse,
  UrgentProjectsResponse,
} from './models/responses/assigned-students.response';
import {
  HighRatingReasonStatsResponse,
  LowRatingReasonStatsResponse,
  AutomatedFlagRejectionReasonStatsResponse,
} from './models/responses/low-rating-reason-stats.response';
import { LatestTashipParamsResponse } from './models/responses/latest-taship-params.response';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(private apiClient: ApiClient) {}

  public fetchLatestTashipParameters(): Observable<
    Resource<LatestTashipParamsResponse>
  > {
    const call = new CallBuilder(
      CallMethod.Get,
      URLS.V3.MENTORS.DASHBOARD.LATEST_TASHIP_PARAMETERS
    ).build();
    return this.apiClient.enqueue(call);
  }

  public fetchVitalMetrics(
    filter: string[]
  ): Observable<Resource<VitalMetricsResponse>> {
    const call = new CallBuilder(
      CallMethod.Get,
      URLS.V3.MENTORS.DASHBOARD.METRICS
    )
      .setParam('from', filter[0])
      .setParam('to', filter[1])
      .build();
    return this.apiClient.enqueue(call);
  }

  public fetchTashipData(): Observable<Resource<any>> {
    const call = new CallBuilder(
      CallMethod.Get,
      URLS.V3.MENTORS.DASHBOARD.FETCH_TASHIP_DATA
    ).build();
    return this.apiClient.enqueue(call);
  }

  public fetchDoubtRatingStats(
    filter: string[]
  ): Observable<Resource<DoubtRatingStatsResponse>> {
    const call = new CallBuilder(
      CallMethod.Get,
      URLS.V3.MENTORS.DASHBOARD.DOUBT_RATING_STATS
    )
      .setParam('from', filter[0])
      .setParam('to', filter[1])
      .cache(300)
      .build();
    return this.apiClient.enqueue(call);
  }

  public fetchSharedCodeInstances(
    filter: string[]
  ): Observable<Resource<number>> {
    const call = new CallBuilder(
      CallMethod.Get,
      URLS.V3.MENTORS.DASHBOARD.SHARED_CODE_INSTANCES
    )
      .setParam('from', filter[0])
      .setParam('to', filter[1])
      .cache(300)
      .build();
    return this.apiClient.enqueue(call);
  }

  public fetchDoubtLowRatingReasonStats(
    filter: string[]
  ): Observable<Resource<LowRatingReasonStatsResponse>> {
    const call = new CallBuilder(
      CallMethod.Get,
      URLS.V3.MENTORS.DASHBOARD.LOW_RATING_REASON_STATS
    )
      .setParam('from', filter[0])
      .setParam('to', filter[1])
      .cache(300)
      .build();
    return this.apiClient.enqueue(call);
  }

  public fetchAutomatedFlagRejectedStats(
    filter: string[]
  ): Observable<Resource<AutomatedFlagRejectionReasonStatsResponse>> {
    const call = new CallBuilder(
      CallMethod.Get,
      URLS.V3.MENTORS.DASHBOARD.AUTOMATED_FLAG_REJECTED_STATS
    )
      .setParam('from', filter[0])
      .setParam('to', filter[1])
      .cache(300)
      .build();
    return this.apiClient.enqueue(call);
  }

  public fetchDoubtHighRatingReasonStats(
    filter: string[]
  ): Observable<Resource<HighRatingReasonStatsResponse>> {
    const call = new CallBuilder(
      CallMethod.Get,
      URLS.V3.MENTORS.DASHBOARD.HIGH_RATING_REASON_STATS
    )
      .setParam('from', filter[0])
      .setParam('to', filter[1])
      .cache(300)
      .build();
    return this.apiClient.enqueue(call);
  }

  public fetchDoubtFlaggingStats(
    filter: string[]
  ): Observable<Resource<DoubtFlagStatsResponse>> {
    const call = new CallBuilder(
      CallMethod.Get,
      URLS.V3.MENTORS.DASHBOARD.DOUBT_FLAGGING_STATS
    )
      .setParam('from', filter[0])
      .setParam('to', filter[1])
      .cache(300)
      .build();
    return this.apiClient.enqueue(call);
  }

  public fetchDoubtFlagData(filter: string): Observable<Resource<any>> {
    const call = new CallBuilder(
      CallMethod.Get,
      URLS.V3.MENTORS.DASHBOARD.DOUBT_FLAGGING_DATA
    )
      .setParam('filter', filter)
      .build();
    return this.apiClient.enqueue(call);
  }

  public deleteFlagRecord(flagId: string): Observable<Resource<any>> {
    const call = new CallBuilder(
      CallMethod.Post,
      URLS.V3.MENTORS.DASHBOARD.DELETE_FLAG
    )
      .setParam('flag_id', flagId)
      .build();
    return this.apiClient.enqueue(call);
  }

  public fetchDoubtResolutionStats(
    filter: string[]
  ): Observable<Resource<DoubtResolutionStatsResponse>> {
    const call = new CallBuilder(
      CallMethod.Get,
      URLS.V3.MENTORS.DASHBOARD.DOUBT_RESOLUTION_STATS
    )
      .setParam('from', filter[0])
      .setParam('to', filter[1])
      .build();
    return this.apiClient.enqueue(call);
  }

  public fetchDoubtPointsStats(
    filter: string[]
  ): Observable<Resource<DoubtPointsStatsResponse>> {
    const call = new CallBuilder(
      CallMethod.Get,
      URLS.V3.MENTORS.DASHBOARD.DOUBT_POINTS_STATS
    )
      .setParam('from', filter[0])
      .setParam('to', filter[1])
      .build();
    return this.apiClient.enqueue(call);
  }

  public fetchDoubtMetrics(
    filter: string[]
  ): Observable<Resource<DoubtMetricsResponse>> {
    const call = new CallBuilder(
      CallMethod.Get,
      URLS.V3.MENTORS.DASHBOARD.DOUBT_METRICS
    )
      .setParam('from', filter[0])
      .setParam('to', filter[1])
      .cache(300)
      .build();
    return this.apiClient.enqueue(call);
  }

  public fetchAssignedStudents(): Observable<
    Resource<AssignedStudentsResponse>
  > {
    const call = new CallBuilder(
      CallMethod.Get,
      URLS.V3.MENTORS.DASHBOARD.ASSIGNED_STUDENTS
    ).build();
    return this.apiClient.enqueue(call);
  }

  public fetchUrgentProjectsForEvaluation(): Observable<
    Resource<UrgentProjectsResponse>
  > {
    const call = new CallBuilder(
      CallMethod.Get,
      URLS.V3.MENTORS.DASHBOARD.GET_URGENT_PROJECTS
    ).build();
    return this.apiClient.enqueue(call);
  }

  public fetchIncompleteProblem(): Observable<any> {
    const call = new CallBuilder(
      CallMethod.Get,
      URLS.V3.MENTORS.DASHBOARD.GET_INCOMPLETE_PROBLEMS
    ).build();
    return this.apiClient.enqueue(call);
  }

  public sendCustomMailToStudents(
    user_ids: number[],
    subject: string,
    message: string
  ): Observable<Resource<any>> {
    const body = {
      user_ids,
      subject,
      message,
    };
    const call = new CallBuilder(
      CallMethod.Post,
      URLS.V3.MENTORS.DASHBOARD.SEND_CUSTOM_MAIL
    )
      .body(body)
      .build();
    return this.apiClient.enqueue(call);
  }

  public fetchTashipLeaveCount(): Observable<Resource<LeaveCountResponse>> {
    const call = new CallBuilder(
      CallMethod.Get,
      URLS.V3.MENTORS.DASHBOARD.LEAVE_COUNT
    ).build();
    return this.apiClient.enqueue(call);
  }
}
