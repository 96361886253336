import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { NavbarHorizontalStyle1Component } from './style-1.component';
import { FuseSharedModule } from '../../../../../../../@fuse/shared.module';
import { FuseNavigationModule } from '../../../../../../../@fuse/components/navigation/navigation.module';

@NgModule({
  declarations: [NavbarHorizontalStyle1Component],
  imports: [
    MatButtonModule,
    MatIconModule,

    FuseSharedModule,
    FuseNavigationModule,
  ],
  exports: [NavbarHorizontalStyle1Component],
})
export class NavbarHorizontalStyle1Module {}
