import { Injectable } from '@angular/core';

declare var posthog: any;
declare let dataLayer: any;

@Injectable({
  providedIn: 'root',
})
export class PosthogService {
  private loader: Promise<boolean>;
  constructor() {}

  /* tslint:disable */
  public init(option: any): Promise<any> {
    this.loader = new Promise((resolve, reject) => {
      !(function (t, e): any {
        var o, n, p, r;
        e.__SV ||
          (((window as any).posthog = e),
          (e._i = []),
          (e.init = function (i, s, a) {
            function g(t, e) {
              var o = e.split('.');
              2 == o.length && ((t = t[o[0]]), (e = o[1])),
                (t[e] = function () {
                  t.push([e].concat(Array.prototype.slice.call(arguments, 0)));
                });
            }
            ((p = t.createElement('script')).type = 'text/javascript'),
              (p.async = !0),
              (p.src = s.api_host + '/static/array.js'),
              (r = t.getElementsByTagName('script')[0]).parentNode.insertBefore(
                p,
                r
              );
            var u = e;
            for (
              void 0 !== a ? (u = e[a] = []) : (a = 'posthog'),
                u.people = u.people || [],
                u.toString = function (t) {
                  var e = 'posthog';
                  return (
                    'posthog' !== a && (e += '.' + a), t || (e += ' (stub)'), e
                  );
                },
                u.people.toString = function () {
                  return u.toString(1) + '.people (stub)';
                },
                o =
                  'capture identify alias people.set people.set_once set_config register register_once unregister opt_out_capturing has_opted_out_capturing opt_in_capturing reset isFeatureEnabled onFeatureFlags'.split(
                    ' '
                  ),
                n = 0;
              n < o.length;
              n++
            )
              g(u, o[n]);
            e._i.push([i, s, a]);
          }),
          (e.__SV = 1));
      })(document, (window as any).posthog || []);
      posthog.init('phc_s4aJa5RpiiZlHbbxy4Y1Btjhosozg9ECrSuJNVrvZuP', {
        api_host: 'https://analytics.codingninjas.com',
        autocapture: false,
        persistence: 'localStorage+cookie',
      });
      resolve(true);
    });

    return this.loader;
  }

  public userDefine(config) {
    if (this.loader) {
      this.loader.then((loaded) => {
        if (loaded) {
          posthog.identify(config.userId, {
            email: config.userEmail,
            college: config.userCollege,
            graduation_year: config.userGraduationYear,
            registration_utm_source: config.registrationUtmSource,
            registration_utm_medium: config.registrationUtmMedium,
            registration_utm_campaign: config.registrationUtmCampaign,
          });
        }
      });
    }
  }

  public pushPosthogEvent(config) {
    if (config.event === 'User Defined' || !config.eventAction) {
      return;
    }

    if (this.loader !== null) {
      this.loader.then((loaded) => {
        if (loaded) {
          var defaultAttributes = {
            eventCategory: config.eventCategory,
            eventAction: config.eventAction,
            eventLabel: config.eventLabel,
          };
          var customAttributes = config.eventData;
          var data = Object.assign({}, defaultAttributes, customAttributes);
          posthog.capture(config.eventAction, data);
        }
      });
    }
  }

  public track() {
    if (this.loader !== null) {
      this.loader.then((loaded) => {
        if (loaded) {
          posthog.capture('$pageview');
        }
      });
    }
  }

  public logout() {
    if (this.loader !== null) {
      this.loader.then((loaded) => {
        if (loaded) {
          posthog.reset();
        }
      });
    }
  }
  /* tslint:enable */
}
