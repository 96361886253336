import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewContainerRef,
} from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { RescheduleSlotModalComponent } from '../../reschedule-slot-modal/reschedule-slot-modal.component';
import { Subscription } from 'rxjs';
import { DoubtService } from '../../../../../doubts/services/doubt.service';

@Component({
  selector: 'mentor-weekoff-leave-apply',
  templateUrl: './weekoff-leave-apply.component.html',
  styleUrls: ['./weekoff-leave-apply.component.scss'],
})
export class WeekoffLeaveApplyComponent implements OnInit, OnDestroy {
  @Input() mentorUser;
  @Input() isTa;
  sub: Subscription;
  // slackData: any;
  dialogRef: MatDialogRef<any>;

  constructor(
    private doubtService: DoubtService,
    public dialog: MatDialog,
    public viewContainerRef: ViewContainerRef,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    // this.sub = this.doubtService.slackDataObservable.subscribe(
    //   (slackResponse) => {
    //     if (slackResponse) {
    //       this.slackData = slackResponse.mentorSlackData[0];
    //       this.cdRef.detectChanges();
    //     }
    //   }
    // );
  }

  openLeaveScheduleModal() {
    if (this.mentorUser) {
      const config = new MatDialogConfig();
      config.viewContainerRef = this.viewContainerRef;
      config.panelClass = 'leave-div-modal';
      this.dialogRef = this.dialog.open(RescheduleSlotModalComponent, config);
      this.dialogRef.componentInstance.taId = this.mentorUser.id;
      this.dialogRef.afterClosed().subscribe((result) => {
        this.dialogRef = null;
      });
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
