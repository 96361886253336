export const environment = {
  production: true,
  baseUrl: 'https://admin-mumbai.codingninjas.com/api/',
  baseNodeUrl: 'https://node-mumbai.codingninjas.com:443/',
  hmr: false,
  codezenUrl: 'https://staging.codingninjas.in/app/',
  mentorsUrl: 'https://test-ninjas-mentors.firebaseapp.com/',
  domainRoot: 'codingninjas.com',
  facebookClientId: '423016508337363',
  googleClientId:
    '727224546303-n3qbta0ihqo93h1sccobhglq1vjoqai6.apps.googleusercontent.com',
  beLoginURL:
    'https://admin-mumbai.codingninjas.com/users/auth/google_oauth2?from_fe=true',
};
