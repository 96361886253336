import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { CoreService } from '../core.service';
import { UserRoles } from '../+state/mentor/models/mentor-user';

@Injectable()
export class WhatsappRoleGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router, private coreService: CoreService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const canActivate = this.checkRoles();
    if (!canActivate) {
      this.router.navigate(['/unauthorised']);
    }
    return canActivate;
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const canActivate = this.checkRoles();
    if (!canActivate) {
      this.router.navigate(['/unauthorised']);
    }
    return canActivate;
  }

  checkRoles(): boolean {
    return (
      this.coreService.userHasRole(UserRoles.ADMIN) ||
      this.coreService.userHasRole(UserRoles.WHATSAPP_MESSAGE_ROLE)
    );
  }
}
