import { Inject, Injectable, Optional } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {
  MY_ACTIVITY_NOTIFICATION_CONFIG,
  MY_ACTIVITY_NOTIFICATION_DEFAULT_CONFIG,
  MyActivityNotificationConfig,
} from './my-activity-notification.config';
import {
  MyActivityNotification,
  MyActivityNotificationOptions,
} from './my-activity-notification';
import {
  ApiClient,
  Call,
  CallBuilder,
  CallMethod,
  Resource,
  URLS,
} from '@codingninjas/networking';
import {
  ACTIVITY_TYPE_TO_ANALYTICS_NAME_MAP,
  MyActivityNotificationModel,
} from '../constants/my-activities-notification.constants';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class MyActivitiesNotificationService {
  private _actionsSource: BehaviorSubject<ActivityNotificationActions> =
    new BehaviorSubject<ActivityNotificationActions>(null);
  $activityNotificationAction: Observable<ActivityNotificationActions> =
    this._actionsSource.asObservable();

  private _monthlyRecapCertificate: Subject<boolean> = new Subject<boolean>();
  monthlyRecapCertificateObservable: Observable<boolean> =
    this._monthlyRecapCertificate.asObservable();

  private _analyticsEventSubject: Subject<NotificationAnalytics> =
    new Subject<NotificationAnalytics>();
  analyticsEventObservable: Observable<NotificationAnalytics> =
    this._analyticsEventSubject.asObservable();

  config: MyActivityNotificationConfig = {};

  constructor(
    @Optional()
    @Inject(MY_ACTIVITY_NOTIFICATION_DEFAULT_CONFIG)
    defaultConfig: MyActivityNotificationConfig,
    @Optional()
    @Inject(MY_ACTIVITY_NOTIFICATION_CONFIG)
    config: MyActivityNotificationConfig,
    private apiClient: ApiClient,
    private router: Router
  ) {
    this.setConfig({ ...defaultConfig, ...config });
  }

  private setConfig(config: MyActivityNotificationConfig): void {
    this.config = { ...this.config, ...config };
  }

  getConfig(): MyActivityNotificationConfig {
    return this.config;
  }

  create(
    notification: MyActivityNotificationModel,
    options?: MyActivityNotificationOptions
  ): MyActivityNotification {
    const noti = new MyActivityNotification(notification).setOptions(options);
    this._actionsSource.next(new AddActivity(noti));
    return noti;
  }

  removeNotification(notificationId: string) {
    this._actionsSource.next(new RemoveActivity(notificationId));
  }

  clearAll() {
    this._actionsSource.next(new ClearAllActivity());
  }

  markAsDelivered(activityIds: []): Observable<Resource<any>> {
    const body = {
      activity_ids: activityIds,
      status: 'delivered',
    };
    const call: Call = new CallBuilder(
      CallMethod.Post,
      URLS.V4.CLASSROOM.USER_NOTIFICATION_ACTIVITY.UPDATE_ACTIVITIES
    )
      .body(body)
      .build();
    return this.apiClient.enqueue<any>(call);
  }

  markAsDismissed(
    activityIds: number[],
    dismissReason?: string
  ): Observable<Resource<any>> {
    const body = {
      activity_ids: activityIds,
      status: 'dismissed',
      dismiss_reason: dismissReason || null,
    };
    const call: Call = new CallBuilder(
      CallMethod.Post,
      URLS.V4.CLASSROOM.USER_NOTIFICATION_ACTIVITY.UPDATE_ACTIVITIES
    )
      .body(body)
      .build();
    return this.apiClient.enqueue<any>(call);
  }

  showMonthlyShareableCertificate() {
    this._monthlyRecapCertificate.next(true);
  }

  triggerCTAClickAnalytics(ctaText, notification, source) {
    const eventName = 'Classroom - Notification CTA Clicked';
    const additionalData = {
      notificationType:
        ACTIVITY_TYPE_TO_ANALYTICS_NAME_MAP[notification.activity_type],
      CTAText: ctaText,
    };
    const analyticsSource = this.processAnalyticsSource(source);
    const baseData = {
      source: analyticsSource,
    };
    this.sendAnalyticsEvent({
      eventName,
      additionalData,
      baseData,
    });
  }

  processAnalyticsSource(source) {
    if (source === 'My Activity') {
      return source;
    } else {
      if (this.router.url === '/app/classroom') {
        return source + ' | My Courses';
      } else {
        return source + ' | Learn Dashboard';
      }
    }
  }

  sendAnalyticsEvent(value: NotificationAnalytics) {
    this._analyticsEventSubject.next(value);
  }
}

export const ADD_ACTIVITY_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_ACTIVITY_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const CLEAR_ALL_ACTIVITY_NOTIFICATION = 'CLEAR_ALL';

export class AddActivity {
  type = ADD_ACTIVITY_NOTIFICATION;
  constructor(public payload: MyActivityNotification) {}
}

export class RemoveActivity {
  type = REMOVE_ACTIVITY_NOTIFICATION;
  constructor(public payload: string) {}
}

export class ClearAllActivity {
  type = CLEAR_ALL_ACTIVITY_NOTIFICATION;
  payload = null;
}

export type ActivityNotificationActions =
  | AddActivity
  | RemoveActivity
  | ClearAllActivity;

export interface NotificationAnalytics {
  eventName: string;
  additionalData: any;
  baseData: any;
}
