import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Subject, Subscription } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { getQueryParams } from '@codingninjas/ninjas-utils/utils/get-query-params.util';
import {
  ApiClient,
  Call,
  CallBuilder,
  CallMethod,
  Resource,
  URLS,
} from '@codingninjas/networking';
import { MARKETING_TOKEN_KEY } from '@codingninjas/ninjas-utils/constants/marketing-token.constants';
import { Observable } from 'rxjs';
import { FN_JOURNEY_ID_SESSION_KEY } from '../../../../models';
import { Store } from '@ngrx/store';
import { AuthState } from '@codingninjas/auth';
import { BreakpointObserver } from '@angular/cdk/layout';
// import "swiper/css/swiper.min.css";
// import 'swiper/css';
declare let windowLoaded;
export interface UbaModel {
  actionSrc?: string;
  actionType?: string;
  timestamp?: number;
  cnid?: string;
  pageName?: string;
  pageTitle?: string;
  problemName?: string;
  problemId?: number;
  appversion?: string;
}

@Injectable({
  providedIn: 'root',
})
export class NaukriAnalyticsService {
  private loader: Promise<boolean>;
  private chatbotLoader: Promise<boolean>;
  private cvId: string;
  landingDataSent = false;
  _breakpointObsSub: Subscription;
  isMobile = false;
  appId = 0;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
    @Inject(PLATFORM_ID) private platformId,
    private apiClient: ApiClient,
    private store: Store<AuthState>,
    @Inject('env') private environment,
    private breakpointObserver: BreakpointObserver
  ) {
    if (
      isPlatformBrowser(this.platformId) &&
      ['publicsection', 'classroom'].includes(this.environment.app)
    ) {
      let cvid;
      this.store.select('auth').subscribe((authData) => {
        if (authData && authData.user) {
          cvid = authData.user.naukri_cvid;
          this.cvId = cvid;
        }
      });
      this.init(cvid);
    }
    if (isPlatformBrowser(this.platformId)) {
      this._breakpointObsSub = this.breakpointObserver
        .observe(['(max-width: 960px)'])
        .subscribe((result) => {
          this.isMobile =
            this.breakpointObserver.isMatched('(max-width: 960px)');
        });
    }
  }

  public init(cvid: string): Promise<boolean> {
    if (isPlatformBrowser(this.platformId)) {
      let tenantId = '12';
      let beaconUrl = 'https://partners.infoedge.com/uba';
      let bulkBeaconUrl =
        'https://partners.infoedge.com/collectorapi/v1/uba/bulk';
      if (this.environment.app === 'publicsection') {
        tenantId = '1';
        beaconUrl = 'https://logs.naukri.com/uba';
        bulkBeaconUrl = 'https://logs.naukri.com/collectorapi/v1/uba/bulk';
        this.appId = 1001;
      }
      if (this.loader != null) {
        return this.loader;
      }
      this.loader = new Promise<boolean>((resolve, reject) => {
        const queuedSuperProps = [];
        const queuedEvents = [];
        (window as any).ub = {
          track: (eventName, eventProps) => {
            queuedEvents.push([eventName, eventProps]);
          },
          register: (properties) => {
            queuedSuperProps.push(properties);
          },
        };
        const script = document.createElement('script');
        const appId = this.appId;
        script.defer = true;
        script.src = '//static.naukimg.com/s/0/1/j/ub_v1.15.min.js';
        script.onload = () => {
          (window as any).ub.init({
            beaconUrl,
            bulkBeaconUrl,
            queuedEvents,
            queuedSuperProps,
            tenantId,
            thirdPartyTds: true,
            autoTrack: false,
            blackList: [],
          });
          (window as any).ub.register({ appId, tenantId });
          resolve(true);
        };
        script.onerror = (error: any) => {
          reject(false);
        };
        document.head.appendChild(script);
        const nTrackScript = document.createElement('script');
        nTrackScript.defer = true;
        nTrackScript.src =
          'https://static.naukimg.com/s/5/105/j/nTrack_v1.min.js';
        document.head.appendChild(nTrackScript);
      });
      this.router.events.subscribe((value) => {
        if (value instanceof NavigationEnd) {
          this._ubaPageView();
          if (!this.landingDataSent) {
            // this.captureLandingData();
            this.landingDataSent = true;
          }
        }
      });
      return this.loader;
    }
  }

  public isNaukriUser(queryParamMap?: any) {
    const naukriCookie = this.cvId;
    const cvId =
      (queryParamMap && queryParamMap.get('cvId')) ||
      this.route.snapshot.queryParams['cvId'];
    const params = getQueryParams();
    const utm_source =
      (queryParamMap && queryParamMap.get('utm_source')) ||
      params['utm_source'] ||
      '';
    return (
      !!cvId ||
      utm_source.toLowerCase() === 'naukri' ||
      (!!naukriCookie && naukriCookie !== 'null')
    );
  }

  public sendEvent(name: string, action: string, extraData: UbaModel) {
    if (isPlatformBrowser(this.platformId)) {
      if (this.loader != null) {
        this.loader.then((loaded) => {
          if (loaded) {
            this._ubaEvent(name, {
              actionSrc: action,
              actionType: 'click',
              ...extraData,
            });
          }
        });
      }
    }
  }

  public checkIos(): boolean {
    const webkit = (window as any).webkit;
    if (
      typeof webkit !== 'undefined' &&
      webkit.messageHandlers &&
      webkit.messageHandlers.companyFollowHandler &&
      webkit.messageHandlers.companyFollowHandler.postMessage
    ) {
      return true;
    }

    return false;
  }

  public setFnJourneyId(journeyId: string) {
    if (!!journeyId) {
      sessionStorage.setItem(FN_JOURNEY_ID_SESSION_KEY, String(journeyId));
    }
  }

  public getFnRedirectionUrl() {
    const journeyId = sessionStorage.getItem(FN_JOURNEY_ID_SESSION_KEY);
    if (journeyId && journeyId !== 'undefined' && journeyId !== 'null') {
      return `https://www.naukri.com/campus/pathfinder/journey?journey-id=${journeyId}`;
    } else {
      return 'https://www.naukri.com/mnjuser/profile';
    }
  }

  public checkAndroid(): boolean {
    if (
      navigator?.userAgent &&
      navigator.userAgent.includes('Android') &&
      (navigator.userAgent.includes('wv') ||
        navigator.userAgent.includes('WebView'))
    ) {
      return true;
    }
    return false;
  }

  public _ubaPageView() {
    if (this.loader != null) {
      this.loader.then((loaded) => {
        if (loaded) {
          const urls = this.router.url.split('/');
          (window as any).tracking.init(
            {
              appId: this.appId,
            },
            {
              pageName: `${urls[1] || ''}/${urls[2] || ''}`,
            }
          );
          this._ubaEvent('pageView', {
            actionSrc: 'Page View',
            actionType: 'view',
          });
        }
      });
    }
  }

  private _ubaEvent(event: string, data: UbaModel) {
    if ((window as any).ub) {
      const urls = this.router.url.split('/');
      const ubaData: UbaModel = {
        ...data,
        cnid: this.cvId,
        timestamp: Date.now(),
        pageName: `${urls[1] || ''}/${urls[2] || ''}`,
        pageTitle: this.titleService.getTitle(),
        appversion: '3.50.24',
      };
      const did = this.getCookie('dId');
      if (!!did) {
        ubaData['dId'] = did;
      }
      (window as any).ub.track(event, ubaData);
    }
  }

  private setCookie(name, value, days) {
    if (isPlatformBrowser(this.platformId)) {
      let expires = '';
      if (days) {
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = '; expires=' + date.toUTCString();
      }
      const domain = this.getDomain();
      document.cookie =
        name +
        '=' +
        (value || '') +
        expires +
        '; path=/ ; domain=' +
        domain +
        ';';
    }
  }

  private getDomain() {
    let domain = window.location.hostname.split('.').slice(1).join('.');
    domain = domain === '' ? 'localhost' : '.' + domain;
    return domain;
  }

  private getCookie(name) {
    if (isPlatformBrowser(this.platformId)) {
      const nameEQ = name + '=';
      const ca = document.cookie.split(';');
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
          c = c.substring(1, c.length);
        }
        if (c.indexOf(nameEQ) === 0) {
          return c.substring(nameEQ.length, c.length);
        }
      }
      return null;
    }
  }

  private isNull(value: any) {
    return (
      value == null || value === 'null' || value === 'undefined' || value === ''
    );
  }

  captureLandingData() {
    const naukriCvId = this.naukriCvId();
    const landingUrl = window.location.host + window.location.pathname;
    const eventType = 'landing';
    const marketingToken: string = this.getCookie(MARKETING_TOKEN_KEY);
    if (isPlatformBrowser(this.platformId)) {
      const checkValueInterval = setInterval(() => {
        if (windowLoaded) {
          let data = {};
          setTimeout(() => {
            const perfEntries =
              window.performance.getEntriesByType('navigation');
            if (perfEntries && perfEntries.length > 0) {
              data = perfEntries[0];
              this.captureNaukriDataDump(
                eventType,
                landingUrl,
                naukriCvId,
                marketingToken,
                data
              ).subscribe(() => {});
            }
          });
          clearInterval(checkValueInterval);
        }
      }, 100);
    }
  }

  captureNaukriDataDump(
    eventType,
    landingUrl,
    naukriCvId,
    marketingToken,
    data = {}
  ) {
    const call = new CallBuilder(
      CallMethod.Post,
      URLS.V3.PARTNER_USER_CAPTURE_DATA
    )
      .setParam('event_type', eventType)
      .setParam('naukri_cvid', naukriCvId)
      .setParam('landing_url', landingUrl)
      .setParam('marketing_token', marketingToken)
      .body({ data })
      .build();
    return this.apiClient.enqueue<any>(call);
  }

  fetchTempTokenNaukri(endpoint, client_id): Observable<Resource<any>> {
    const tkn = this.getCookie('ninja_auth_token');
    const call: Call = new CallBuilder(CallMethod.Get, endpoint)
      .setHeader('Authorization', `Token ${tkn}`)
      .setParam('response_type', 'code')
      .setParam('client_id', client_id)
      .setParam('grant_type', 'authorization_code')
      .build();

    return this.apiClient.enqueue<any>(call);
  }

  naukriCvId() {
    return this.cvId || getQueryParams()['cvId'];
  }

  isNaukriDomain(): boolean {
    return true;
  }
}
