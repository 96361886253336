import { createFeatureSelector, createSelector } from '@ngrx/store';
import { assignmentInitialState, DoubtData } from '../reducers/doubt.reducer';
import { Doubt } from '../models/doubt';

const doubtState = createFeatureSelector<DoubtData>('doubt');

const selectDoubts = createSelector(doubtState, (data: DoubtData) => {
  return data.doubts;
});

const selectDoubtActivityStatus = createSelector(
  doubtState,
  (data: DoubtData) => {
    return data.doubtActivityStatus;
  }
);

const selectDoubtTarget = createSelector(doubtState, (data: DoubtData) => {
  return data.targetReviewCount;
});

const selectdoubtRejectCount = createSelector(doubtState, (data: DoubtData) => {
  return data.doubtRejectCount;
});

const selectDoubtAssignment = createSelector(
  doubtState,
  selectDoubtActivityStatus,
  (data: DoubtData, status: boolean) => {
    if (status) {
      return data.assignment;
    }
    return assignmentInitialState;
  }
);

const selectActiveDoubtIds = createSelector(doubtState, (data: DoubtData) => {
  return data.activeDoubtIds;
});

const selectActionStatus = createSelector(doubtState, (data: DoubtData) => {
  return data.actionStatus;
});

const selectTimerExpireCount = createSelector(doubtState, (data: DoubtData) => {
  return data.timerExpireCount;
});

const selectResolvedDoubtIds = createSelector(doubtState, (data: DoubtData) => {
  return data.resolvedDoubtIds;
});

const selectPendingOnUserDoubtIds = createSelector(
  doubtState,
  (data: DoubtData) => {
    return data.pendingOnUserDoubtIds;
  }
);

const selectReviewPendingDoubtIds = createSelector(
  doubtState,
  (data: DoubtData) => {
    return data.reviewPendingDoubtIds;
  }
);

const selectAssignNextStatus = createSelector(
  doubtState,
  selectDoubtActivityStatus,
  (data: DoubtData, status) => {
    if (status) {
      return data.assignNextStatus;
    }
    return false;
  }
);

const selectDoubtObject = createSelector(
  doubtState,
  (data: DoubtData, props) => {
    return data.doubts.get(props.doubtId);
  }
);

const selectResolvedDoubtCount = createSelector(
  doubtState,
  (data: DoubtData) => {
    return data.totalResolvedDoubts;
  }
);

const selectPendingDoubtCount = createSelector(
  doubtState,
  (data: DoubtData) => {
    return data.totalPendingDoubts;
  }
);

const selectReviewPendingDoubtsCount = createSelector(
  doubtState,
  (data: DoubtData) => {
    return data.totalReviewPendingDoubts;
  }
);

const selectDoubtCount = createSelector(
  doubtState,
  selectResolvedDoubtCount,
  selectPendingDoubtCount,
  selectReviewPendingDoubtsCount,
  (
    data: DoubtData,
    resolvedDoubtCount,
    pendingDoubtCount,
    reviewPendingDoubtCount
  ) => {
    return {
      resolved: resolvedDoubtCount,
      pending: pendingDoubtCount,
      review_pending: reviewPendingDoubtCount,
    };
  }
);

const selectAvailableDoubtIds = createSelector(
  doubtState,
  (data: DoubtData) => {
    return data.availableDoubtIds;
  }
);

const selectAvailableDoubtsCount = createSelector(
  doubtState,
  (data: DoubtData) => {
    return data.totalAvailableDoubts;
  }
);

const selectCourseModules = createSelector(doubtState, (data: DoubtData) => {
  return data.mentorCourseModules;
});

const selectOnlineMode = createSelector(doubtState, (data: DoubtData) => {
  return data.onlineMode;
});

const selectMentorsAvailableDoubts = createSelector(
  doubtState,
  (data: DoubtData) => {
    return data.mentorsAvailableDoubts;
  }
);

const selectMentorsSlotActive = createSelector(
  doubtState,
  (data: DoubtData) => {
    return data.slotActive;
  }
);

const getDoubt = createSelector(
  selectDoubts,
  (doubts: Map<number, Doubt>, props) => {
    return doubts.get(props.doubtId);
  }
);

export const doubtSelectors = {
  selectDoubts,
  getDoubt,
  selectDoubtAssignment,
  selectDoubtActivityStatus,
  selectActiveDoubtIds,
  selectActionStatus,
  selectTimerExpireCount,
  selectResolvedDoubtIds,
  selectPendingOnUserDoubtIds,
  selectAssignNextStatus,
  selectDoubtObject,
  selectDoubtCount,
  selectAvailableDoubtIds,
  selectReviewPendingDoubtIds,
  selectAvailableDoubtsCount,
  selectCourseModules,
  selectOnlineMode,
  selectMentorsAvailableDoubts,
  selectDoubtTarget,
  selectdoubtRejectCount,
  selectMentorsSlotActive,
};
