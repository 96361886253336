import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MonthlyShareableCertificateResponse } from '../../../../models/monthly_shareable_certificate.response';
import { AuthAnalyticsService } from '@codingninjas/ninjas-utils';
import {
  getLinkedinShareLink,
  getWhatsappShareLink,
} from '@codingninjas/ninjas-utils/utils/social-sharing-links';

@Component({
  selector: 'mentor-monthly-shareable',
  templateUrl: './monthly-shareable-popup.component.html',
  styleUrls: ['./monthly-shareable-popup.component.scss'],
})
export class MonthlyShareablePopupComponent {
  certificateData: MonthlyShareableCertificateResponse;
  source: string;
  sharingText =
    'My monthly progress at Coding Ninjas. Looking forward to next month!';
  whatsappPopoverVisible = false;
  linkedinPopoverVisible = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data,
    private analyticsService: AuthAnalyticsService
  ) {
    this.certificateData = this.data.certificateData;
    this.source = this.data.source;
    this.popupShownEvent();
  }

  popupShownEvent() {
    this.analyticsService.sendEvent({
      name: 'TA Monthly Achievement Sharing - Pop up Shown',
      category: 'TA Monthly Achievement Sharing',
      action: 'Pop up Shown',
      label: this.source,
      data: {
        user_id: this.certificateData.user_id,
      },
    });
  }

  shareOnSocial(source: 'LinkedIn' | 'Whatsapp') {
    if (source === 'LinkedIn') {
      this.linkedinButtonClicked();
    } else {
      this.whatsappButtonClicked();
    }
    this.analyticsService.sendEvent({
      name: `TA Monthly Achievement Sharing - Share on ${source} Clicked`,
      category: 'TA Monthly Achievement Sharing',
      action: `Share on ${source} Clicked`,
      label: this.source,
      data: {
        user_id: this.certificateData.user_id,
      },
    });
  }

  linkedinButtonClicked() {
    if (this.certificateData?.certificate_sharing_link) {
      const url = `${this.certificateData.certificate_sharing_link}?source=LinkedIn`;
      window.open(
        getLinkedinShareLink(url, this.sharingText, this.sharingText),
        '_blank'
      );
    }
  }

  whatsappButtonClicked() {
    if (this.certificateData?.certificate_sharing_link) {
      const text =
        this.sharingText +
        `: ${this.certificateData.certificate_sharing_link}?source=Whatsapp`;
      window.open(getWhatsappShareLink(text), '_blank');
    }
  }
}
