<div class="main_container">
  <span class="heading">Please provide reject reason</span>
  <form [formGroup]="applicationFormGroup">
    <div class="reason-div">
      <mat-form-field appearance="outline" class="reason-textarea">
        <mat-label>Reason...</mat-label>
        <textarea #reason matInput formControlName="reason"></textarea>
        <mat-error
          *ngIf="applicationFormGroup.get('reason').hasError('required')"
          >Reason is Required</mat-error
        >
        <mat-error
          *ngIf="applicationFormGroup.get('reason').hasError('minlength')"
          >Reason is too short</mat-error
        >
        <mat-error
          *ngIf="applicationFormGroup.get('reason').hasError('whitespace')"
          >Enter Valid Reason</mat-error
        >
      </mat-form-field>
    </div>
    <div class="action-div">
      <button type="reset" (click)="closeModal()">Cancel</button>
      <div class="next-button">
        <button
          (mouseenter)="showErrors()"
          (click)="submitApplication()"
          type="submit"
          class="submit-button loading-button expand"
          [class.disabled]="applicationFormGroup.invalid"
        >
          Submit
        </button>
      </div>
    </div>
  </form>
</div>
