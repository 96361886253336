import { NgModule } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';

import { QuickPanelComponent } from './quick-panel.component';
import { FuseSharedModule } from '../../../../../@fuse/shared.module';

@NgModule({
  declarations: [QuickPanelComponent],
  imports: [
    MatDividerModule,
    MatListModule,
    MatSlideToggleModule,

    FuseSharedModule,
  ],
  exports: [QuickPanelComponent],
})
export class QuickPanelModule {}
