import { Injectable } from '@angular/core';
import { NinjasWebSession } from '../models/ninjas-web-session.model';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
import { BrowserService } from './browser.service';
import { UTMData } from '../models/utm-data.model';
import { isNullOrUndefined } from '../utils/blank-check.util';

@Injectable({
  providedIn: 'root',
})
export class WebSessionService {
  private KEY_NINJAS_WEB_SESSION_COOKIE = 'ninjas_web_session';
  private UTC_OFFSET_IST = 330;
  private SESSION_EXPIRY_IN_MINUTES = 30;

  constructor(private browserService: BrowserService) {}

  isFreshSession(currentUtmData: UTMData, referrer: string): boolean {
    const previousWebSession: NinjasWebSession = this.getWebSessionFromCookie();

    // Check for Session Expired or First Session
    if (isNullOrUndefined(previousWebSession)) {
      return true;
    }

    const isSourceBlank =
      !currentUtmData.source || currentUtmData.source.trim() === '';
    const isCampaignBlank =
      !currentUtmData.campaign || currentUtmData.campaign.trim() === '';
    const isReferrerBlank =
      !document.referrer || document.referrer.trim() === '';
    if (isSourceBlank && isCampaignBlank && !isReferrerBlank) {
      const currentReferrerHost = new URL(referrer).hostname;
      const previousReferrerHost = previousWebSession.referrerHost;

      const hasReferrerChanged = currentReferrerHost !== previousReferrerHost;

      if (hasReferrerChanged) {
        return true;
      }
    }

    const previousUtmString = JSON.stringify(previousWebSession.utmData);
    const currentUtmString = JSON.stringify(currentUtmData);
    const hasCampaignChanged = previousUtmString !== currentUtmString;

    if (hasCampaignChanged) {
      return true;
    }

    return false;
  }

  calculateSessionExpiry(): Date {
    const afterThirtyMinutesDate = new Date();
    afterThirtyMinutesDate.setMinutes(
      afterThirtyMinutesDate.getMinutes() + this.SESSION_EXPIRY_IN_MINUTES
    );
    const afterThirtyMinutesTime = afterThirtyMinutesDate.getTime();

    const endOfDayTime = Number(
      dayjs().utcOffset(this.UTC_OFFSET_IST).endOf('day').unix() * 1000
    );

    const currentSessionExpiresAt = Math.min(
      afterThirtyMinutesTime,
      endOfDayTime
    );

    return new Date(currentSessionExpiresAt);
  }

  setWebSessionCookie(referrer: string, utmData: UTMData) {
    let referrerHost = null;
    try {
      referrerHost = new URL(referrer).hostname;
    } catch {
      referrerHost = referrer;
    }

    const webSession: NinjasWebSession = { referrerHost, utmData };

    this.browserService.setCookie(
      this.KEY_NINJAS_WEB_SESSION_COOKIE,
      JSON.stringify(webSession),
      this.calculateSessionExpiry()
    );
  }

  getWebSessionFromCookie(): NinjasWebSession {
    const webSessionString = this.browserService.getCookie(
      this.KEY_NINJAS_WEB_SESSION_COOKIE
    );
    if (webSessionString) {
      return JSON.parse(webSessionString);
    }
    return undefined;
  }

  resetWebSession() {
    this.browserService.setCookie(
      this.KEY_NINJAS_WEB_SESSION_COOKIE,
      null,
      new Date()
    );
  }
}
