import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NetworkingModule, ResourceFactory } from '@codingninjas/networking';
import { AuthModule } from '@codingninjas/auth';
import { environment } from '../../environments/environment';
import { EffectsModule } from '@ngrx/effects';
import {
  NinjasResourceFactory,
  NinjasRouterSerializer,
  NinjasUtilsModule,
} from '@codingninjas/ninjas-utils';
import { SharedUiModule } from '@codingninjas/shared-ui';
import { StoreModule } from '@ngrx/store';
import {
  routerReducer,
  RouterStateSerializer,
  StoreRouterConnectingModule,
} from '@ngrx/router-store';

import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { doubtReducer } from './+state/doubt/reducers/doubt.reducer';
import { DoubtEffects } from './+state/doubt/effects/doubt.effects';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { NotificationModule } from '@codingninjas/notification';
import { MainComponent } from './components/app-shell/main/main.component';
import { FuseSharedModule } from '../../@fuse/shared.module';
import { FuseSidebarModule } from '../../@fuse/components/sidebar/sidebar.module';
import { QuickPanelModule } from './components/app-shell/quick-panel/quick-panel.module';
import { ToolbarModule } from './components/app-shell/toolbar/toolbar.module';
import { NavbarModule } from './components/app-shell/navbar/navbar.module';
import { FooterModule } from './components/app-shell/footer/footer.module';
import { ContentModule } from './components/app-shell/content/content.module';
import {
  mentorInitialState,
  mentorReducer,
} from './+state/mentor/reducers/mentor.reducer';
import { MentorEffects } from './+state/mentor/effects/mentor.effects';
import { RescheduleSlotModalComponent } from './components/app-shell/reschedule-slot-modal/reschedule-slot-modal.component';
import { TaLeaveFormComponent } from './components/app-shell/reschedule-slot-modal/ta-leave-form/ta-leave-form.component';
import { TaSlotRescheduleFormComponent } from './components/app-shell/reschedule-slot-modal/ta-slot-reschedule-form/ta-slot-reschedule-form.component';
import { TaLeaveResponseComponent } from './components/app-shell/reschedule-slot-modal/ta-leave-response/ta-leave-response.component';
import { TaSlotRescheduleResponseComponent } from './components/app-shell/reschedule-slot-modal/ta-slot-reschedule-response/ta-slot-reschedule-response.component';

@NgModule({
  declarations: [
    MainComponent,
    RescheduleSlotModalComponent,
    TaLeaveFormComponent,
    TaSlotRescheduleFormComponent,
    TaLeaveResponseComponent,
    TaSlotRescheduleResponseComponent,
  ],
  imports: [
    AuthModule.forNinjasConfig({
      config: {
        baseUrl: environment.baseUrl,
      },
      socialProviders: {
        facebook: {
          clientId: environment.facebookClientId,
          scope: 'public_profile,email,user_friends',
          version: 'v3.0',
        },
        google: {
          clientId: environment.googleClientId,
          scope: 'email profile',
          be_login_url: environment.beLoginURL,
        },
        naukri: {
          clientId: 'epGcmxLwMa83qBqS',
          redirectUrl: 'https://api.codingninjas.com/naukri_login',
          prod: environment.production,
        },
      },
    }),
    CommonModule,
    NetworkingModule.forRoot({ baseUrl: environment.baseUrl }),
    RouterModule,
    EffectsModule.forRoot([DoubtEffects]),
    EffectsModule.forFeature([MentorEffects]),
    NinjasUtilsModule.forRoot(environment),
    RouterModule,
    SharedUiModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatTooltipModule,
    MatTabsModule,
    NotificationModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    StoreModule.forRoot(
      { router: routerReducer, doubt: doubtReducer },
      {
        metaReducers: !environment.production ? [] : [],
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true,
        },
      }
    ),
    StoreModule.forFeature('mentor', mentorReducer, {
      initialState: mentorInitialState,
    }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    StoreRouterConnectingModule.forRoot({
      stateKey: 'router', // name of reducer key
    }),
    FuseSharedModule,
    FuseSidebarModule,
    QuickPanelModule,
    ToolbarModule,
    NavbarModule,
    FooterModule,
    ContentModule,
  ],
  providers: [
    { provide: ResourceFactory, useClass: NinjasResourceFactory },
    { provide: RouterStateSerializer, useClass: NinjasRouterSerializer },
  ],
})
export class CoreModule {}
