import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AuthQuery, AuthState, LocalLogout, SocialAuth } from '../../+state';
import { SocialProvider } from '../../social-login';
import { Meta } from '@angular/platform-browser';
import { AnalyticsService } from '../../service/analytics.service';
import { HttpParams } from '@angular/common/http';
import { Location } from '@angular/common';
import { ClientRoutingBuilder } from '@codingninjas/shared-ui/builders/client-routing.builder';

@Component({
  selector: 'auth-auth-page',
  templateUrl: './auth-page.component.html',
  styleUrls: ['./auth-page.component.scss'],
})
export class AuthPageComponent implements OnInit, OnDestroy {
  authenticated$: Observable<boolean>;
  redirect: string;
  logged_out: string;
  redirectQParams: Params;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<AuthState>,
    private metaService: Meta,
    private analyticsService: AnalyticsService,
    private location: Location
  ) {
    this.authenticated$ = this.store.pipe(select(AuthQuery.getIfAuthenticated));
  }

  ngOnInit() {
    this.sendLoginPopUpEvent();
    this.redirect = this.route.snapshot.queryParams['redirect'];
    this.logged_out = this.route.snapshot.queryParams['logged_out'];
    this.metaService.addTag({
      name: 'robots',
      content: 'noindex, nofollow',
    });
    if (this.logged_out) {
      const [path, query] = this.location.path().split('?');
      let params = new HttpParams({ fromString: query });
      params = params.delete('logged_out');
      const paramString = params.toString();
      this.location.replaceState(path, paramString);
      this.store.dispatch(new LocalLogout());
    }
    this.authenticated$.subscribe((isLoggedIn) => {
      if (isLoggedIn) {
        if (this.redirect) {
          const routingBuilder = new ClientRoutingBuilder(
            this.router,
            this.redirect
          );
          this.redirectQParams = routingBuilder.queryParams;
          this.redirect = routingBuilder.build();
          this.router.navigate([this.redirect], {
            queryParams: this.redirectQParams,
          });
        } else {
          this.router.navigate(['/'], { replaceUrl: true });
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.redirect = '';
    this.metaService.removeTag('name="robots"');
  }

  facebookLogin() {
    this.store.dispatch(new SocialAuth(SocialProvider.FACEBOOK));
  }

  googleLogin() {
    this.store.dispatch(new SocialAuth(SocialProvider.GOOGLE));
  }

  sendLoginPopUpEvent() {
    this.analyticsService.sendEvent({
      name: 'Login Popup Shown',
      category: 'Login',
      action: 'Login Popup Shown',
    });
  }
}
