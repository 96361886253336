import { AuthActions, AuthActionTypes } from './auth.actions';
import { AuthUser, BasicUserDetails } from './models/auth-user';
import { NetworkingError } from '@codingninjas/networking';

/**
 * Interface to the part of the Store containing AuthState
 * and other information related to AuthData.
 */
export interface AuthData {
  user: AuthUser;
  new_user: boolean;
  first_time_login: boolean;
  user_access_token: string;
  provider: string;
  isAuthenticating: boolean;
  isAuthenticated: boolean;
  fbMergeState: number;
  fbMergeEmail: string;
  fbMergeToken: string;
  error: NetworkingError;
  naukriUnverified: boolean;
  naukriFlowUser: number;
  basicDetails: BasicUserDetails;
  classroomPaid: boolean;
  reverse_auto_login_triggered: boolean;
  registrationUtmSource?: string;
  registrationUtmMedium?: string;
  registrationUtmCampaign?: string;
  naukri_mobile_present?: boolean;
  naukri_gender_present?: boolean;
  naukri_college_present?: boolean;
}

/***
 Scenarios of naukriUnverified variable -
 Case - 1
 isAuthenticated = true; Logged in (no significance of naurkiUnverified)
 Case - 2
 isAuthenticated = false;
 Case - 2a
 naukriUnverified = true, Partially Login (Logged in on naukri buthas email unverified email on CN)
 Case - 2b
 naukriUnverified = false, Completely Logged Out
 /*/

export interface AuthState {
  readonly auth: AuthData;
}

export const initialState: AuthData = {
  user: null,
  new_user: false,
  first_time_login: false,
  user_access_token: null,
  provider: null,
  isAuthenticating: false,
  error: null,
  fbMergeState: null,
  fbMergeEmail: null,
  fbMergeToken: null,
  isAuthenticated: false,
  naukriUnverified: false,
  basicDetails: null,
  classroomPaid: false,
  reverse_auto_login_triggered: false,
  naukriFlowUser: 0,
  registrationUtmSource: null,
  registrationUtmMedium: null,
  registrationUtmCampaign: null,
  naukri_mobile_present: null,
  naukri_gender_present: null,
  naukri_college_present: null,
};

export function authReducer(
  state = initialState,
  action: AuthActions
): AuthData {
  switch (action.type) {
    case AuthActionTypes.SocialAuth:
      return { ...state, isAuthenticating: true, provider: action.payload };
    case AuthActionTypes.Authenticating:
      return { ...state, isAuthenticating: true };
    case AuthActionTypes.FBMergeState:
      return {
        ...state,
        fbMergeState: action.state,
        fbMergeEmail: action.email,
        fbMergeToken: action.token,
      };
    case AuthActionTypes.Authenticated:
    case AuthActionTypes.PreAuth:
      return {
        ...state,
        ...action.payload,
        isAuthenticating: false,
        isAuthenticated: true,
        fbMergeState: null,
        fbMergeEmail: null,
        fbMergeToken: null,
        error: null,
      };

    case AuthActionTypes.LocalLogout:
      return { ...initialState };

    case AuthActionTypes.AuthenticationError:
      return {
        ...initialState,
        error: action.payload,
        isAuthenticating: false,
      };

    case AuthActionTypes.ResetAuthenticationError:
      return { ...state, error: null };

    case AuthActionTypes.SetUser:
      return { ...state, user: action.payload };

    case AuthActionTypes.SetUserProfileImage:
      const user = state.user;
      user.image = action.payload;
      return { ...state, user };

    case AuthActionTypes.SetUserPhoneNumber:
      const phone_number = action.payload?.phone_number;
      const phone_country_code = action.payload?.phone_country_code;
      return {
        ...state,
        user: { ...state.user, phone_number, phone_country_code },
      };
    case AuthActionTypes.SetEmailVerificationAndBasicDetails:
      return {
        ...state,
        ...action.payload,
      };
    case AuthActionTypes.SetAuthAndUser:
      return {
        ...state,
        user_access_token: action.payload.user_access_token,
        user: action.payload.authUser,
        isAuthenticating: false,
        isAuthenticated: true,
        error: null,
      };
    case AuthActionTypes.ChangeUserState:
      return {
        ...state,
        user: action.data,
      };

    case AuthActionTypes.TriggerReverseAutoLogin:
      return {
        ...state,
        reverse_auto_login_triggered:
          action.payload.reverse_auto_login_triggered,
      };
    case AuthActionTypes.SetCollegeValidationDetails:
      return {
        ...state,
        naukri_mobile_present: action.payload.naukri_mobile_present,
        naukri_gender_present: action.payload.naukri_gender_present,
        naukri_college_present: action.payload.naukri_college_present,
      };
    default:
      return state;
  }
}
