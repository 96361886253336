import { Call } from './call';
import { CallMethod } from './call-method';
import {
  HttpHeaders,
  HttpParameterCodec,
  HttpParams,
} from '@angular/common/http';
import { isNullOrUndefined } from '../../../../ninjas-utils/src/lib/utils/blank-check.util';
import { CacheInterceptor } from './cache.interceptor';

export class CallBuilder {
  private _httpParams: HttpParams;
  private _httpHeaders: HttpHeaders = new HttpHeaders();
  private _callBody: any;
  private _enableLoading = true;
  private _shouldCache = false;
  private _callRetryCount = 0;

  public constructor(public method: CallMethod, public url: string) {}

  get httpParams(): HttpParams {
    return this._httpParams;
  }

  get httpHeaders(): HttpHeaders {
    return this._httpHeaders;
  }

  get callBody(): any {
    return this._callBody;
  }

  get wouldLoad(): boolean {
    return this._enableLoading;
  }

  get shouldCache(): boolean {
    return this._shouldCache;
  }

  get callRetryCount(): number {
    return this._callRetryCount;
  }

  public params(httpParams: HttpParams): CallBuilder {
    this._httpParams = httpParams;
    return this;
  }

  public setParam(param: string, value: string, encode?: boolean): CallBuilder {
    if (isNullOrUndefined(this._httpParams)) {
      if (encode) {
        this._httpParams = new HttpParams({ encoder: new CustomEncoder() });
      } else {
        this._httpParams = new HttpParams();
      }
    }
    this._httpParams = this._httpParams.set(param, value);
    return this;
  }

  public forcePreventCache() {
    this.setParam('request_differentiator', new Date().getTime().toString());
    return this;
  }

  public appendParam(param: string, value: string): CallBuilder {
    if (isNullOrUndefined(this._httpParams)) {
      this._httpParams = new HttpParams();
    }
    this._httpParams = this._httpParams.append(param, value);
    return this;
  }

  public setFrontendUrl(): CallBuilder {
    const currentUrlObject = new URL(window.location.href);
    const sourceUrl = currentUrlObject.origin + currentUrlObject.pathname;

    if ([CallMethod.Post].includes(this.method)) {
      if (isNullOrUndefined(this._callBody)) {
        this._callBody = {};
      }

      this._callBody = {
        ...this._callBody,
        source_url: sourceUrl,
      };
    } else {
      this.setParam('source_url', sourceUrl);
    }

    return this;
  }

  public headers(httpHeaders: HttpHeaders): CallBuilder {
    this._httpHeaders = httpHeaders;
    return this;
  }

  public setHeader(param: string, value: string): CallBuilder {
    if (isNullOrUndefined(this._httpHeaders)) {
      this._httpHeaders = new HttpHeaders();
    }
    this._httpHeaders = this._httpHeaders.set(param, value);
    return this;
  }

  public body(body: any): CallBuilder {
    this._callBody = body;
    return this;
  }

  public retry(count: number): CallBuilder {
    if (count > 0) {
      this._callRetryCount = count;
    } else {
      this._callRetryCount = 0;
    }
    return this;
  }

  public cache(cacheTillSeconds: number = 0): CallBuilder {
    this._shouldCache = true;
    this.setHeader(CacheInterceptor.HEADER_KEY, cacheTillSeconds.toString());
    return this;
  }

  public silent(): CallBuilder {
    this._enableLoading = false;
    return this;
  }

  public build(): Call {
    return new Call(this);
  }
}

class CustomEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }

  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}
