import { Action } from '@ngrx/store';
import { Doubt } from '../models/doubt';
import { CourseModule } from '../models/course-module';

export enum DoubtActionTypes {
  NOOP_ACTION = '[Doubt] NoopAction',
  ASSIGN_DOUBT = '[Doubt] AssignDoubt',
  DOUBT_ASSIGNED = '[Doubt] DoubtAssigned',
  ASSIGNING_DOUBT = '[Doubt] AssigningDoubt',
  DOUBT_ASSIGN_ERROR = '[Doubt] DoubtAssignError',
  FETCH_ACTIVE_DOUBTS = '[Doubt] FetchActiveDoubts',
  ACTIVE_DOUBTS_FETCHED = '[Doubt] ActiveDoubtsFetched',
  FETCH_RESOLVED_DOUBTS = '[Doubt] FetchResolvedDoubts',
  RESOLVED_DOUBTS_FETCHED = '[Doubt] ResolvedDoubtsFetched',
  FETCH_PENDING_ON_USER_DOUBTS = '[Doubt] FetchPendingOnUserDoubts',
  PENDING_ON_USER_DOUBTS_FETCHED = '[Doubt] PendingOnUserDoubtsFetched',
  ACCEPT_DOUBT = '[Doubt] AcceptDoubt',
  ACCEPTED_DOUBT = '[Doubt] AcceptedDoubt',
  UNASSIGN_DOUBT = '[Doubt] UnassignDoubt',
  REJECT_DOUBT = '[Doubt] RejectDoubt',
  UPDATE_DOUBT_ACTIVITY_STATUS = '[Doubt] UpdateDoubtActivityStatus',
  LOAD_MORE_DOUBT = '[Doubt] LoadMoreDoubt',
  UPDATE_RESOLVE_COUNTER = '[Doubt] UpdateResolveCounter',
  UPDATE_DOUBT_REJECT_COUNTER = '[Doubt] UpdateDoubtRejectCounter',
  CHANGE_ACTION_STATUS = '[Doubt] ChangeActionStatus',
  UPDATE_ASSIGN_NEXT_STATUS = '[Doubt] UpdateAssignNextStatus',
  ADD_TO_ACTIVE_DOUBTS = 'Doubt] AddToActiveDoubts',
  REMOVE_FROM_ACTIVE_DOUBTS = 'Doubt] RemoveFromActiveDoubts',
  ADD_TO_RESOLVED_DOUBTS = 'Doubt] AddToResolvedDoubts',
  REMOVE_FROM_RESOLVED_DOUBTS = 'Doubt] RemoveFromResolvedDoubts',
  ADD_TO_PENDING_DOUBTS = 'Doubt] AddToPendingDoubts',
  REMOVE_FROM_PENDING_DOUBTS = 'Doubt] RemoveFromPendingDoubts',
  REMOVE_ASSIGNED_DOUBT = '[Doubt] RemoveAssignedDoubt',
  UPDATE_DOUBT_OBJECT = '[Doubt] UpdateDoubtObject',
  REJECTED_DOUBT = '[Doubt] RejectedDoubt',
  UNASSIGNED_DOUBT = '[Doubt] UnassignedDoubt',
  FETCH_AVAILABLE_DOUBTS = '[Doubt] FetchAvailableDoubts',
  AVAILABLE_DOUBTS_FETCHED = '[Doubt] AvailableDoubtsFetched',
  ADD_TO_AVAILABLE_DOUBTS = 'Doubt] AddToAvailableDoubts',
  REMOVE_FROM_AVAILABLE_DOUBTS = '[Doubt] RemoveFromAvailableDoubts',
  ASSIGN_AND_ACCEPT_DOUBT = '[Doubt] AssignAndAcceptDoubt',
  FETCH_REVIEW_PENDING_DOUBTS = '[Doubt] FetchReviewPendingDoubts',
  REVIEW_PENDING_DOUBTS_FETCHED = '[Doubt] ReviewPendingDoubtsFetched',
  ADD_TO_REVIEW_PENDING_DOUBTS = '[Doubt] AddToReviewPendingDoubts',
  REMOVE_FROM_REVIEW_PENDING_DOUBTS = '[Doubt] RemoveFromReviewPendingDoubts',
  FETCH_MENTOR_COURSE_MODULES = '[Doubt] FetchMentorCourseModules',
  MENTOR_COURSE_MODULES_FETCHED = '[Doubt] MentorCourseModulesFetched',
  EMPTY_AVAILABLE_DOUBT_IDS = '[Doubt] EmptyAvailableDoubtIds',
  UPDATE_ONLINE_MODE = '[Doubt] UpdateOnlineMode',
  FETCH_AVAILABLE_DOUBTS_COUNT = '[Doubt] FetchAvailableDoubtsCount',
  AVAILABLE_DOUBTS_COUNT_FETCHED = '[Doubt] AvailableDoubtsCountFetched',
}

export class NoopAction implements Action {
  readonly type = DoubtActionTypes.NOOP_ACTION;
}

export class AssignDoubt implements Action {
  readonly type = DoubtActionTypes.ASSIGN_DOUBT;
}

export class DoubtAssigned implements Action {
  readonly type = DoubtActionTypes.DOUBT_ASSIGNED;
  constructor(
    public doubt: Doubt,
    public timer: number,
    public mentorsAvailableDoubts: number,
    public timerCompleteAction: string,
    public doubtPoints: number
  ) {}
}

export class DoubtAssignError implements Action {
  readonly type = DoubtActionTypes.DOUBT_ASSIGN_ERROR;
  constructor(public error: string) {}
}

export class AssigningDoubt implements Action {
  readonly type = DoubtActionTypes.ASSIGNING_DOUBT;
}

export class FetchActiveDoubts implements Action {
  readonly type = DoubtActionTypes.FETCH_ACTIVE_DOUBTS;
}

export class ActiveDoubtsFetched implements Action {
  readonly type = DoubtActionTypes.ACTIVE_DOUBTS_FETCHED;
  constructor(public doubts: Doubt[]) {}
}

export class AcceptDoubt implements Action {
  readonly type = DoubtActionTypes.ACCEPT_DOUBT;
  constructor() {}
}

export class AcceptedDoubt implements Action {
  readonly type = DoubtActionTypes.ACCEPTED_DOUBT;
  constructor(public doubt: Doubt) {}
}

export class RejectDoubt implements Action {
  readonly type = DoubtActionTypes.REJECT_DOUBT;
  constructor(public reason: string) {}
}

export class UpdateDoubtActivityStatus implements Action {
  readonly type = DoubtActionTypes.UPDATE_DOUBT_ACTIVITY_STATUS;
  constructor(public status: boolean) {}
}

export class LoadMoreDoubt implements Action {
  readonly type = DoubtActionTypes.LOAD_MORE_DOUBT;
  constructor() {}
}

export class UpdateResolveCounter implements Action {
  readonly type = DoubtActionTypes.UPDATE_RESOLVE_COUNTER;
  constructor(
    public resolveTargetCount: number,
    public reviewPendingTargetCount: number,
    public totalTargetAchivied: number,
    public doubtWarningColor: string
  ) {}
}

export class UpdateDoubtRejectCounter implements Action {
  readonly type = DoubtActionTypes.UPDATE_DOUBT_REJECT_COUNTER;
  constructor(public totalRejectCount: number) {}
}

export class ChangeActionStatus implements Action {
  readonly type = DoubtActionTypes.CHANGE_ACTION_STATUS;
  constructor(
    public loading: boolean,
    public remark: string,
    public error: string
  ) {}
}

export class FetchResolvedDoubts implements Action {
  readonly type = DoubtActionTypes.FETCH_RESOLVED_DOUBTS;
  constructor(
    public limit: number,
    public offset: number,
    public from: string,
    public to: string,
    public filter_applied: boolean
  ) {}
}

export class ResolvedDoubtsFetched implements Action {
  readonly type = DoubtActionTypes.RESOLVED_DOUBTS_FETCHED;
  constructor(
    public doubts: Doubt[],
    public totalCount: number,
    public filterApplied: boolean
  ) {}
}

export class FetchPendingOnUserDoubts implements Action {
  readonly type = DoubtActionTypes.FETCH_PENDING_ON_USER_DOUBTS;
  constructor(public limit: number, public offset: number) {}
}

export class PendingOnUserDoubtsFetched implements Action {
  readonly type = DoubtActionTypes.PENDING_ON_USER_DOUBTS_FETCHED;
  constructor(public doubts: Doubt[], public totalCount: number) {}
}

export class UpdateAssignNextStatus implements Action {
  readonly type = DoubtActionTypes.UPDATE_ASSIGN_NEXT_STATUS;
  constructor(public status: boolean) {}
}

export class AddToActiveDoubts implements Action {
  readonly type = DoubtActionTypes.ADD_TO_ACTIVE_DOUBTS;
  constructor(public doubtId: number) {}
}

export class RemoveFromActiveDoubts implements Action {
  readonly type = DoubtActionTypes.REMOVE_FROM_ACTIVE_DOUBTS;
  constructor(public doubtId: number) {}
}

export class AddToResolvedDoubts implements Action {
  readonly type = DoubtActionTypes.ADD_TO_RESOLVED_DOUBTS;
  constructor(public doubtId: number) {}
}

export class RemoveFromResolvedDoubts implements Action {
  readonly type = DoubtActionTypes.REMOVE_FROM_RESOLVED_DOUBTS;
  constructor(public doubtId: number) {}
}

export class AddToPendingDoubts implements Action {
  readonly type = DoubtActionTypes.ADD_TO_PENDING_DOUBTS;
  constructor(public doubtId: number) {}
}

export class RemoveFromPendingDoubts implements Action {
  readonly type = DoubtActionTypes.REMOVE_FROM_PENDING_DOUBTS;
  constructor(public doubtId: number) {}
}

export class RemoveAssignedDoubt implements Action {
  readonly type = DoubtActionTypes.REMOVE_ASSIGNED_DOUBT;
  constructor() {}
}

export class UpdateDoubtObject implements Action {
  readonly type = DoubtActionTypes.UPDATE_DOUBT_OBJECT;
  constructor(public doubt: Doubt) {}
}

export class UnassignDoubt implements Action {
  readonly type = DoubtActionTypes.UNASSIGN_DOUBT;
  constructor() {}
}

export class RejectedDoubt implements Action {
  readonly type = DoubtActionTypes.REJECTED_DOUBT;
  constructor() {}
}

export class UnassignedDoubt implements Action {
  readonly type = DoubtActionTypes.UNASSIGNED_DOUBT;
  constructor() {}
}

export class FetchAvailableDoubts implements Action {
  readonly type = DoubtActionTypes.FETCH_AVAILABLE_DOUBTS;
  constructor(
    public limit: number,
    public offset: number,
    public courseModuleId: number,
    public createdAtFilter: string,
    public selectedDoubtId: number
  ) {}
}

export class AvailableDoubtsFetched implements Action {
  readonly type = DoubtActionTypes.AVAILABLE_DOUBTS_FETCHED;
  constructor(public doubts: Doubt[], public totalCount: number) {}
}

export class AddToAvailableDoubts implements Action {
  readonly type = DoubtActionTypes.ADD_TO_AVAILABLE_DOUBTS;
  constructor(public doubtId: number) {}
}

export class RemoveFromAvailableDoubts implements Action {
  readonly type = DoubtActionTypes.REMOVE_FROM_AVAILABLE_DOUBTS;
  constructor(public doubtId: number) {}
}

export class AssignAndAcceptDoubt implements Action {
  readonly type = DoubtActionTypes.ASSIGN_AND_ACCEPT_DOUBT;
  constructor(public doubtId: number) {}
}

export class FetchReviewPendingDoubts implements Action {
  readonly type = DoubtActionTypes.FETCH_REVIEW_PENDING_DOUBTS;
  constructor(public limit: number, public offset: number) {}
}

export class ReviewPendingDoubtsFetched implements Action {
  readonly type = DoubtActionTypes.REVIEW_PENDING_DOUBTS_FETCHED;
  constructor(public doubts: Doubt[], public totalCount: number) {}
}

export class AddToReviewPendingDoubts implements Action {
  readonly type = DoubtActionTypes.ADD_TO_REVIEW_PENDING_DOUBTS;
  constructor(public doubtId: number) {}
}

export class RemoveFromReviewPendingDoubts implements Action {
  readonly type = DoubtActionTypes.REMOVE_FROM_REVIEW_PENDING_DOUBTS;
  constructor(public doubtId: number) {}
}

export class FetchMentorCourseModules implements Action {
  readonly type = DoubtActionTypes.FETCH_MENTOR_COURSE_MODULES;
  constructor() {}
}

export class MentorCourseModulesFetched implements Action {
  readonly type = DoubtActionTypes.MENTOR_COURSE_MODULES_FETCHED;
  constructor(public courseModules: CourseModule[]) {}
}

export class EmptyAvailableDoubtIds implements Action {
  readonly type = DoubtActionTypes.EMPTY_AVAILABLE_DOUBT_IDS;
  constructor() {}
}

export class UpdateOnlineMode implements Action {
  readonly type = DoubtActionTypes.UPDATE_ONLINE_MODE;
  constructor(public onlineMode: boolean) {}
}

export class FetchAvailableDoubtsCount implements Action {
  readonly type = DoubtActionTypes.FETCH_AVAILABLE_DOUBTS_COUNT;
  constructor() {}
}

export class AvailableDoubtsCountFetched implements Action {
  readonly type = DoubtActionTypes.AVAILABLE_DOUBTS_COUNT_FETCHED;
  constructor(
    public mentorsAvailableDoubts: number,
    public slotActive: boolean
  ) {}
}

export type DoubtActions =
  | AssigningDoubt
  | AssignDoubt
  | DoubtAssigned
  | DoubtAssignError
  | FetchActiveDoubts
  | ActiveDoubtsFetched
  | AcceptDoubt
  | AcceptedDoubt
  | RejectDoubt
  | UpdateDoubtActivityStatus
  | LoadMoreDoubt
  | UpdateResolveCounter
  | UpdateDoubtRejectCounter
  | ChangeActionStatus
  | FetchResolvedDoubts
  | ResolvedDoubtsFetched
  | FetchPendingOnUserDoubts
  | PendingOnUserDoubtsFetched
  | UpdateAssignNextStatus
  | AddToActiveDoubts
  | RemoveFromActiveDoubts
  | AddToResolvedDoubts
  | RemoveFromResolvedDoubts
  | AddToPendingDoubts
  | RemoveFromPendingDoubts
  | RemoveAssignedDoubt
  | UpdateDoubtObject
  | RejectedDoubt
  | UnassignedDoubt
  | UnassignDoubt
  | FetchAvailableDoubts
  | AvailableDoubtsFetched
  | AddToAvailableDoubts
  | RemoveFromAvailableDoubts
  | FetchReviewPendingDoubts
  | ReviewPendingDoubtsFetched
  | AddToReviewPendingDoubts
  | RemoveFromReviewPendingDoubts
  | FetchMentorCourseModules
  | MentorCourseModulesFetched
  | EmptyAvailableDoubtIds
  | UpdateOnlineMode
  | FetchAvailableDoubtsCount
  | AvailableDoubtsCountFetched;
