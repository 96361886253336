import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MyErrorStateMatcher } from '../../../core/components/app-shell/reschedule-slot-modal/my-error-state-matcher';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'mentor-doubt-reject-reason-modal',
  templateUrl: './doubt-reject-reason-modal.component.html',
  styleUrls: ['./doubt-reject-reason-modal.component.css'],
})
export class DoubtRejectReasonModalComponent implements OnInit {
  isLoading = false;
  applicationFormGroup: UntypedFormGroup;
  matcher = new MyErrorStateMatcher();

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<any>
  ) {
    this.applicationFormGroup = this.formBuilder.group({
      reason: [
        '',
        [
          Validators.required,
          Validators.minLength(20),
          this.noWhitespaceValidator,
        ],
      ],
    });
  }

  ngOnInit(): void {}

  submitApplication() {
    if (this.applicationFormGroup.valid) {
      const rejectFormData = this.applicationFormGroup.getRawValue();
      const reason: string = rejectFormData.reason.trim();
      this.dialogRef.close({ reason });
    }
  }

  showErrors() {
    if (this.applicationFormGroup.invalid) {
      this.markErrors();
    }
  }

  markErrors() {
    this.matcher.setMarked(true);
  }

  closeModal() {
    const reason = '';
    this.dialogRef.close({ reason });
  }

  public noWhitespaceValidator(control: UntypedFormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }
}
