<div class="main_container">
  <mat-tab-group color="accent">
    <mat-tab label="Leave">
      <mentor-ta-leave-form
        [taId]="taId"
        [currentSlots]="currentSlots"
      ></mentor-ta-leave-form>
    </mat-tab>
    <!--    <mat-tab label="Reschedule">-->
    <!--      <mentor-ta-slot-reschedule-form-->
    <!--        [taId]="taId"-->
    <!--        [slots]="slots"-->
    <!--        [currentSlots]="currentSlots"-->
    <!--        [request_number]="request_number"-->
    <!--        *ngIf="currentSlots"-->
    <!--      ></mentor-ta-slot-reschedule-form>-->
    <!--    </mat-tab>-->
  </mat-tab-group>
</div>
