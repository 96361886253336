import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
  authReducer,
  initialState as authInitialState,
} from './+state/auth.reducer';
import { AuthEffects } from './+state/auth.effects';
import { IProviders } from './social-login';
import { AuthPageComponent } from './components/auth-page/auth-page.component';
import { AuthGuard } from './auth.guard';
import { SocialLoginButtonComponent } from './components/social-login-button/social-login-button.component';
import { LoginDialogComponent } from './components/login-dialog/login-dialog.component';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ReactiveFormsModule } from '@angular/forms';
import { LoginSignupComponent } from './components/login-signup/login-signup.component';
import { InitialLoginFormComponent } from './components/initial-login-form/initial-login-form.component';
import { UserLoginFormComponent } from './components/user-login-form/user-login-form.component';
import { MinifiedNotificationModule } from '@codingninjas/minified-notification';
import { AuthSetup } from './auth.setup';
import { INinjasAuthConfig } from './social-login/ninjas-auth-config.interface';
import { CommonProductResolver } from '@codingninjas/shared-ui/resolvers/common-product.resolver';
import { ZenButtonModule } from '@codingninjashq/zen-ui';

export function setupFactory(authSetup: AuthSetup) {
  return () => {
    return authSetup.load();
  };
}

import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import {
  coursesFeatureKey,
  coursesReducer,
} from './+state/course/courses.reducers';
import {
  userActionFeatureKey,
  userActionReducer,
} from './+state/user-actions/user-actions.reducers';

@NgModule({
  imports: [
    MatExpansionModule,
    MatIconModule,
    CommonModule,
    MatDialogModule,
    StoreModule.forFeature('auth', authReducer, {
      initialState: authInitialState,
    }),
    StoreModule.forFeature(coursesFeatureKey, coursesReducer),
    StoreModule.forFeature(userActionFeatureKey, userActionReducer),
    EffectsModule.forFeature([AuthEffects]),
    ReactiveFormsModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MinifiedNotificationModule,
    MatCheckboxModule,
    ZenButtonModule,
  ],
  providers: [
    AuthSetup,
    {
      provide: APP_INITIALIZER,
      useFactory: setupFactory,
      deps: [AuthSetup],
      multi: true,
    },
    AuthEffects,
    AuthGuard,
    CommonProductResolver,
  ],
  declarations: [
    AuthPageComponent,
    SocialLoginButtonComponent,
    LoginDialogComponent,
    LoginSignupComponent,
    InitialLoginFormComponent,
    UserLoginFormComponent,
  ],
  exports: [
    AuthPageComponent,
    SocialLoginButtonComponent,
    UserLoginFormComponent,
    LoginDialogComponent,
    LoginSignupComponent,
  ],
})
export class AuthModule {
  public static forNinjasConfig(
    ninjasConfig: INinjasAuthConfig
  ): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        {
          provide: 'socialLoginConfig', // you can also use InjectionToken
          useValue: ninjasConfig.socialProviders,
        },
        {
          provide: 'ninjasAuthConfig', // you can also use InjectionToken
          useValue: ninjasConfig.config,
        },
      ],
    };
  }

  public static forProviders(
    providers: IProviders
  ): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        {
          provide: 'socialLoginConfig', // you can also use InjectionToken here
          useValue: providers,
        },
      ],
    };
  }
}
