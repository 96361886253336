export function isNullOrUndefined(value: any) {
  return value === null || value === undefined;
}

export function containsNullOrUndefined(value: any) {
  return value.includes(null) || value.includes(undefined);
}

export const isNotNullOrUndefined = (value: any) =>
  value !== null && value !== undefined;
