import { Injectable } from '@angular/core';

declare var webengage: any;
declare let dataLayer: any;

@Injectable({
  providedIn: 'root',
})
export class WebengageService {
  private loaded = false;
  constructor() {}

  /* tslint:disable */
  public init(option: any): Promise<any> {
    return new Promise((resolve, reject) => {
      if (typeof webengage !== 'undefined') {
        resolve(true);
      }

      var scriptTag = document.createElement('script');
      scriptTag.id = '_webengage_script_tag';
      document.head.appendChild(scriptTag);

      !(function (w, e, b, n, g): any {
        function o(e, t) {
          e[t[t.length - 1]] = function () {
            r.__queue.push([t.join('.'), arguments]);
          };
        }
        var i,
          s,
          r = w[b],
          z = ' ',
          l = 'init options track screen onReady'.split(z),
          a = 'feedback survey notification'.split(z),
          c = 'options render clear abort'.split(z),
          p = 'Open Close Submit Complete View Click'.split(z),
          u = 'identify login logout setAttribute'.split(z);
        if (!r || !r.__v) {
          for (
            w[b] = r =
              {
                __queue: [],
                is_spa: 1, //Change this to 0 if you do not wish to use default SPA behaviour of WebEngage SDK
                __v: '6.0',
                user: {},
              },
              i = 0;
            i < l.length;
            i++
          )
            o(r, [l[i]]);
          for (i = 0; i < a.length; i++) {
            for (r[a[i]] = {}, s = 0; s < c.length; s++)
              o(r[a[i]], [a[i], c[s]]);
            for (s = 0; s < p.length; s++) o(r[a[i]], [a[i], 'on' + p[s]]);
          }
          for (i = 0; i < u.length; i++) o(r.user, ['user', u[i]]);
          setTimeout(function () {
            var f = e.createElement('script'),
              d = e.getElementById('_webengage_script_tag');
            (f.type = 'text/javascript'),
              (f.async = !0),
              (f.src =
                ('https:' == e.location.protocol
                  ? 'https://ssl.widgets.webengage.com'
                  : 'http://cdn.widgets.webengage.com') +
                '/js/webengage-min-v-6.0.js'),
              d.parentNode.insertBefore(f, d);
          });
        }
      })(window, document, 'webengage');
      webengage.init('~47b66064');
      resolve(true);
    });
  }

  public userDefine(config) {
    if (typeof webengage === 'undefined') {
      return;
    }

    webengage.onReady(() => {
      let webengageCUID = webengage.util.getWebengageCookie().cuid;
      if (!webengageCUID || webengageCUID != config.userId) {
        webengage.user.login(config.userId);
      }
      //Set user attributes
      webengage.user.setAttribute({
        we_first_name: config.userName.split(' ')[0],
        we_last_name: config.userName.split(' ')[1],
        we_phone: config.userName.userPhoneNumber,
        user_type: config.userType,
        id: config.userId,
        we_email: config.userEmail,
        college: config.userCollege,
        branch: config.userBranch,
        is_employee: config.userIsEmployee,
        graduation_year: config.userGraduationYear,
        $last_login: new Date(),
        gender: config.userGender,
        userType: config.userType,
        userActiveCourseProductCount: config.userActiveCourseProductCount,
        userActiveCourseProductName: config.userActiveCourseProductName,
        userActiveCourseProductId: config.userActiveCourseProductId,
        userActiveCourseProductStartDate:
          config.userActiveCourseProductStartDate,
        userActiveCourseProductEndDate: config.userActiveCourseProductEndDate,
        userActiveCourseProductShiftedEndDate:
          config.userActiveCourseProductShiftedEndDate,
        userActiveCourseProductCategory: config.userActiveCourseProductCategory,
        userActiveCourseProductClassroomVersion:
          config.userActiveCourseProductClassroomVersion,
        leaderboardLeague: config.leaderboardLeague,
        latestNPSRating: config.latestNPSRating,
        userCollegeId: config.userCollegeId,
        userAwardsKey: config.userAwardsKey,
        userAwardsCount: config.userAwardsCount,
        registrationUtmSource: config.registrationUtmSource,
        registrationUtmMedium: config.registrationUtmMedium,
        registrationUtmCampaign: config.registrationUtmCampaign,
      });
    });
  }

  public pushWebEngageEvent(config) {
    if (
      typeof webengage !== 'undefined' &&
      config.event !== 'User Defined' &&
      !!config.eventAction
    ) {
      var defaultAttributes = {
        eventCategory: config.eventCategory,
        eventAction: config.eventAction,
        eventLabel: config.eventLabel,
      };
      var customAttributes = config.eventData;
      var data = Object.assign({}, defaultAttributes, customAttributes);
      webengage.track(config.eventAction, data);
    }
  }

  public logout() {
    if (typeof webengage !== 'undefined') {
      webengage.user.logout();
    }
  }
  /* tslint:enable */
}
