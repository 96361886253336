import { ApplicationRef, Inject, Injectable } from '@angular/core';
import { SocialAuthData } from './social-auth-data';
import { SocialProvider } from './social-provider';
import { IProvider } from './provider.interface';
import { DOCUMENT } from '@angular/common';

declare let gapi: any;
declare let FB: any;

@Injectable({
  providedIn: 'root',
})
export class SocialAuthService {
  gauth: any = null;
  oneTapScriptLoaded = false;
  private _facebookSdkLoaded: Promise<void>;

  constructor(
    @Inject('socialLoginConfig') private config,
    @Inject(DOCUMENT) private document: Document,
    appRef: ApplicationRef
  ) {}

  backend_callback_promise = new Promise<void>((resolve, reject) => {
    resolve();
  });

  loadSocialProvidersInit() {
    Object.keys(this.config).forEach((provider) => {
      this._loadSocialProvider(provider, this.config[provider]);
    });
  }

  socialLogin(
    provider: SocialProvider,
    origin: string,
    credential = ''
  ): Promise<SocialAuthData> {
    this.loadSocialProvidersInit();
    return new Promise<SocialAuthData>((resolve, reject) => {
      switch (provider) {
        case SocialProvider.GOOGLE:
          const be_login_url = this.config.google.be_login_url;
          this.backend_callback_promise.then(() => {
            const openedWindow = window.open(
              be_login_url,
              'winname',
              'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=600,height=500'
            );
            window.addEventListener(
              'message',
              (e) => {
                if (be_login_url.startsWith(e.origin.trim())) {
                  resolve({
                    provider,
                    token: String(e.data).trim(),
                    origin,
                  });
                }
              },
              false
            );
            const checkWindowInterval = setInterval(() => {
              if (openedWindow && openedWindow.closed) {
                clearInterval(checkWindowInterval);
                reject('Window closed without authentication');
              }
            }, 1000);
          });

          break;
        case SocialProvider.FACEBOOK:
          this._facebookSdkLoaded.then(() => {
            FB.getLoginStatus((response: any) => {
              if (response.status === 'connected') {
                const token = response.authResponse.accessToken;
                resolve({ provider, token, origin });
              } else {
                FB.login(
                  (loginResponse: any) => {
                    if (loginResponse.status === 'connected') {
                      const token = loginResponse.authResponse.accessToken;
                      resolve({ provider, token, origin });
                    } else {
                      reject(loginResponse);
                    }
                  },
                  {
                    scope: this.config['facebook'].scope,
                    auth_type: 'rerequest',
                  }
                );
              }
            });
          });
          break;
        case SocialProvider.GOOGLE_SINGLE_SIGN_IN:
          resolve({
            provider: SocialProvider.GOOGLE_SINGLE_SIGN_IN,
            token: credential,
            origin,
          });
          break;
        case SocialProvider.NAUKRI:
          const d = this.document;
          const config = this.config[SocialProvider.NAUKRI];
          let naukriJs;
          const naukriId = 'naukri-jssdk';
          const naukriRef: any = d.getElementsByTagName('script')[0];
          naukriJs = d.createElement('script');
          naukriJs.id = naukriId;
          naukriJs.defer = true;
          naukriJs.src =
            'https://static.naukri.com/s/0/0/j/naukri_login_sdk_v8.min.js';

          naukriJs.onload = () => {
            (window as any)
              .naukri_oauth({
                vendorCode: config.clientId,
                redirectUrl: config.redirectUrl,
                vendorName: 'Coding Ninjas',
                env: 'prod',
              })
              .linkNaukriAccount({
                errorCallback: (err) => {
                  // tslint:disable-next-line:no-console
                  console.log('Naukri login error', err);
                  reject(err);
                },
              });
          };

          naukriRef.parentNode.insertBefore(naukriJs, naukriRef);
          break;
      }
    });
  }

  socialLogout(provider: SocialProvider): Promise<boolean> {
    this.loadSocialProvidersInit();
    return new Promise<boolean>((resolve, reject) => {
      switch (provider) {
        case SocialProvider.GOOGLE:
          const gElement = document.getElementById('gSignout');
          if (typeof gElement !== 'undefined' && gElement != null) {
            gElement.remove();
          }
          let gSignout;
          const d = document;
          const ref: any = d.getElementsByTagName('script')[0];
          gSignout = d.createElement('script');
          gSignout.src = 'https://accounts.google.com/Logout';
          gSignout.type = 'text/html';
          gSignout.id = 'gSignout';
          ref.parentNode.insertBefore(gSignout, ref);
          resolve(true);
          break;
        case SocialProvider.FACEBOOK:
          FB.logout(() => {
            resolve(true);
          });
          break;
      }
    });
  }

  private _fetchGoogleToken(): string {
    const currentUser = this.gauth.currentUser.get();
    return currentUser.getAuthResponse().id_token;
  }

  private _loadSocialProvider(provider: string, config: IProvider) {
    const d = this.document;
    const current = this;
    switch (provider) {
      case 'facebook':
        this._facebookSdkLoaded = new Promise<void>((resolve) => {
          let fbJs;
          const id = 'facebook-jssdk';
          const fbRef: any = this.document.getElementsByTagName('script')[0];

          // Check if Facebook SDK is already loaded
          if (this.document.getElementById(id)) {
            this.initFacebookSDK(config);
            resolve();
            return;
          }

          fbJs = this.document.createElement('script');
          fbJs.id = id;
          fbJs.defer = true;
          fbJs.src = '//connect.facebook.net/en_US/sdk.js';

          fbJs.onload = () => {
            this.initFacebookSDK(config);
            resolve();
          };

          fbRef.parentNode.insertBefore(fbJs, fbRef);
        });
        break;
      case 'google':
        let gJs;
        const gRef: any = d.getElementsByTagName('script')[0];
        gJs = d.createElement('script');
        gJs.defer = true;
        gJs.src = '//apis.google.com/js/platform.js';
        const host = window.location.protocol + '//' + window.location.host;
        gJs.onload = () => {
          gapi.load('auth2', () => {
            if (current.isFacebookApp()) {
              gapi.auth2.init({
                client_id: config.clientId,
                scope: config.scope,
                ux_mode: 'redirect',
                redirect_uri: host + '/auth',
              });
            } else {
              gapi.auth2.init({
                client_id: config.clientId,
                scope: config.scope,
              });
            }
          });
        };
        gRef.parentNode.insertBefore(gJs, gRef);
        break;
      case SocialProvider.NAUKRI:
        break;
    }
  }

  private isFacebookApp() {
    const ua = navigator.userAgent || navigator.vendor;
    return (
      ua.indexOf('FBAN') > -1 ||
      ua.indexOf('FBAV') > -1 ||
      ua.indexOf('Instagram') > -1
    );
  }

  private initFacebookSDK(config: IProvider) {
    FB.init({
      appId: config.clientId,
      status: true,
      cookie: true,
      xfbml: true,
      version: config.version,
    });
  }

  loadOneTapScript(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.oneTapScriptLoaded) {
        resolve();
      }
      const d = this.document;
      let gsi;
      gsi = d.createElement('script');
      gsi.defer = true;
      gsi.onload = () => {
        this.oneTapScriptLoaded = true;
        resolve();
      };
      gsi.src = 'https://accounts.google.com/gsi/client';
      document.getElementsByTagName('head')[0].appendChild(gsi);
    });
  }
}
