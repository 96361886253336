import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  UntypedFormBuilder,
  FormControl,
  UntypedFormGroup,
  FormGroupDirective,
  NgForm,
  NumberValueAccessor,
  Validators,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { DashboardService } from '../../../../../dashboard/dashboard.service';
import { SlotScheduleRequestService } from '../../../../../slot-schedule-request/services/slot-schedule-request.service';
import { MyErrorStateMatcher } from '../my-error-state-matcher';
import { Slot } from '../../../../models/slot';

@Component({
  selector: 'mentor-ta-leave-form',
  templateUrl: './ta-leave-form.component.html',
  styleUrls: ['./ta-leave-form.component.css'],
})
export class TaLeaveFormComponent implements OnInit, OnChanges {
  @Input() taId: number;
  @Input() currentSlots: Slot[];

  isLoading = false;
  todayDate: any;
  applicationFormGroup: UntypedFormGroup;
  matcher = new MyErrorStateMatcher();
  requestLeaveResult: any;
  errorReason: string;
  from: Date;
  to: Date;
  leaveCount: number;
  leavesTakenThisMonth: number;
  totalLeavesAllowedInMonth: number;
  errorMessage: string;

  constructor(
    private apiService: SlotScheduleRequestService,
    public dialogRef: MatDialogRef<TaLeaveFormComponent>,
    private formBuilder: UntypedFormBuilder,
    private _dashboardService: DashboardService
  ) {}

  ngOnInit() {
    this.fetchTashipLeaveCount();
  }

  ngOnChanges(changes: SimpleChanges) {
    let currDateAvailable = true;
    changes?.currentSlots?.currentValue?.forEach((slot) => {
      currDateAvailable =
        currDateAvailable &&
        (slot?.slot_id === 0 || this.apiService.slotAvailable(slot.slot));
    });
    if (currDateAvailable) {
      this.todayDate = new Date(new Date().getTime());
    } else {
      this.todayDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
    }
    this.applicationFormGroup = this.formBuilder.group({
      fromDate: [this.todayDate, Validators.required],
      toDate: [this.todayDate, Validators.required],
      reason: ['', Validators.required],
    });
  }

  fetchTashipLeaveCount() {
    this._dashboardService.fetchTashipLeaveCount().subscribe((resource) => {
      this.isLoading = resource.isLoading();
      if (resource.isSuccessful()) {
        this.errorReason = null;
        this.from = resource.data.start_date;
        this.to = resource.data.end_date;
        this.leaveCount = resource.data.leave_count;
        this.leavesTakenThisMonth = resource.data.leaves_taken_this_month;
        this.totalLeavesAllowedInMonth =
          resource.data.total_leaves_allowed_in_month;
      } else if (resource.isUnsuccessful()) {
        this.errorReason = resource.error.message;
      }
    });
  }

  showErrors() {
    if (this.applicationFormGroup.invalid) {
      this.markErrors();
    }
  }

  markErrors() {
    this.matcher.setMarked(true);
  }

  submitApplication() {
    if (this.applicationFormGroup.valid) {
      const applicationFormData = this.applicationFormGroup.getRawValue();
      const data: any = {
        fromDate: applicationFormData.fromDate.toLocaleDateString('en-GB'),
        toDate: applicationFormData.toDate.toLocaleDateString('en-GB'),
        reason: applicationFormData.reason,
        ta_id: this.taId,
      };
      this.requestLeave(data);
    }
  }

  requestLeave(data) {
    this.apiService.requestLeaveApplication(data).subscribe((resource) => {
      this.isLoading = resource.isLoading();
      if (resource.isSuccessful()) {
        this.requestLeaveResult = resource.data.slot_schedule_request;
      } else if (resource.error != null) {
        if (resource.error.message === 'already-requested') {
          this.applicationFormGroup.controls['toDate'].setErrors({
            'already-requested': true,
          });
          this.applicationFormGroup.controls['fromDate'].setErrors({
            'already-requested': true,
          });
        } else if (resource.error.message === 'different-months') {
          this.applicationFormGroup.controls['toDate'].setErrors({
            'different-months': true,
          });
        } else {
          this.applicationFormGroup.setErrors({
            error: true,
          });
          this.errorMessage = resource.error.message;
        }
      }
    });
  }

  closeModal() {
    this.dialogRef.close();
  }
}
