import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { NavbarVerticalStyle1Component } from './style-1.component';
import { FuseSharedModule } from '../../../../../../../@fuse/shared.module';
import { FuseNavigationModule } from '../../../../../../../@fuse/components/navigation/navigation.module';

@NgModule({
  declarations: [NavbarVerticalStyle1Component],
  imports: [
    MatButtonModule,
    MatIconModule,

    FuseSharedModule,
    FuseNavigationModule,
  ],
  exports: [NavbarVerticalStyle1Component],
})
export class NavbarVerticalStyle1Module {}
