export const CONSTANTS = {
  DEFAULTS: {
    PLACEHOLDER: {
      IMAGES: {
        DEFAULT:
          'https://files.codingninjas.com/optimised/72e3f188-79a1-465f-90ca-27262d769841-8505.png',
        COURSE_BG:
          'https://files.codingninjas.com/optimised/0000000000001099.png',
        NINJA_AVATAARS: [
          'https://files.codingninjas.in/ninja-user-1-9717.svg',
          'https://files.codingninjas.in/ninja-user-2-9718.svg',
          'https://files.codingninjas.in/ninja-user-3-9719.svg',
          'https://files.codingninjas.in/ninja-user-4-9720.svg',
          'https://files.codingninjas.in/ninja-user-5-9721.svg',
          'https://files.codingninjas.in/ninja-user-6-9722.svg',
          'https://files.codingninjas.in/ninja-user-7-9723.svg',
          'https://files.codingninjas.in/ninja-user-8-9724.svg',
          'https://files.codingninjas.in/ninja-user-9-9725.svg',
          'https://files.codingninjas.in/ninja-user-10-9726.svg',
          'https://files.codingninjas.in/ninja-user-11-9727.svg',
          'https://files.codingninjas.in/ninja-user-12-9728.svg',
        ],
      },
    },

    // Default Timezone identifier, India
    TIMEZONE: {
      OFFSET: '+05:30',
      IDENTIFIER: 'IST',
    },
    LOGIN_FROM_HEADER: 'header',
  },
  ENGLISH_LANGUAGE: 'english',
  HINDI_ENGLISH_LANGUAGE: 'hinglish',
  // USER_COUNTRY: 'user_country',
  INDIA_VIEW_MAP: {
    HEADER: {
      HOME: true,
      EVENTS: true,
      COURSES: true,
      CAREER_TRACKS: true,
      REGISTER: true,
      PRACTICE: true,
      PLACEMENT_CELL: true,
      CAREER_CAMP: true,
      BLOGS: true,
    },

    FOOTER: {
      CAREER_CAMP: true,
      ABOUT_US: true,
      CAREERS: true,
      BLOGS: true,
      BUG_BOUNTY: true,
      TERMS_AND_CONDITIONS: true,
      PRICING_AND_REFUND: true,
      PRIVACY_POLICY: true,
      HIRE_TALENT: true,
      TOLL_FREE_CONTACT: true,
      PRESS_RELEASE: true,
      OUR_STUDENTS: true,
    },
  },
  NON_INDIA_VIEW_MAP: {
    HEADER: {
      HOME: true,
      EVENTS: true,
      COURSES: true,
      CAREER_TRACKS: true,
      REGISTER: true,
      PRACTICE: true,
      PLACEMENT_CELL: false,
      CAREER_CAMP: true,
      BLOGS: true,
    },

    FOOTER: {
      CAREER_CAMP: false,
      ABOUT_US: true,
      CAREERS: true,
      BLOGS: true,
      BUG_BOUNTY: true,
      TERMS_AND_CONDITIONS: true,
      PRICING_AND_REFUND: false,
      PRIVACY_POLICY: true,
      HIRE_TALENT: true,
      TOLL_FREE_CONTACT: false,
      PRESS_RELEASE: true,
      OUR_STUDENTS: true,
    },
  },
  ALLOWED_COUNTRIES: [101],
  TOLL_FREE_NUMBER: '1800-123-3598',
  FOOTER_EMAIL_CONTACT: {
    CAREER_CAMP: 'career.camp@codingninjas.com',
    OTHERS: 'contact@codingninjas.com',
  },
  EVENT_CATEGORY: {
    SCHOLARSHIP: 'SCHOLARSHIP',
    CONTEST: 'CONTEST',
  },
};

export const EMAIL_REGEX =
  '^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$';

// Regex pattern to validate phone numbers with or without country codes, allowing the use of "+".
// Supports various formats, e.g., 9418674536, +1234567890, 123-456-7890, (123) 456 7890, etc.
// Does not guarantee phone number validity; only checks for correct format.
export const PHONE_REGEX =
  '^\\+?\\d{0,3}[-.\\s]?\\(?\\d{3}\\)?[-.\\s]?\\d{3}[-.\\s]?\\d{4}$';
export const PINCODE_REGEX = '^[1-9][0-9]{5}$';
