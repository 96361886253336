import {
  Component,
  OnDestroy,
  OnInit,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FuseConfigService } from '../../../../../@fuse/services/config.service';
import { FuseSidebarService } from '../../../../../@fuse/components/sidebar/sidebar.service';
import { navigation } from '../../../navigation';
import { select, Store } from '@ngrx/store';
import { MentorUser } from '../../../+state/mentor/models/mentor-user';
import {
  MentorData,
  MentorState,
} from '../../../+state/mentor/reducers/mentor.reducer';
import { Logout } from '@codingninjas/auth';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { ProjectStudentListingsComponent } from '../../../../project-evaluation/components/ta-stats-listings/project-student-listings/project-student-listings.component';
import { RescheduleSlotModalComponent } from '../reschedule-slot-modal/reschedule-slot-modal.component';
import { CoreService } from '../../../core.service';
import { MonthlyShareablePopupComponent } from '../monthly-shareable/components/monthly-shareable-popup.component';
import { AuthAnalyticsService } from '@codingninjas/ninjas-utils';

@Component({
  selector: 'toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ToolbarComponent implements OnInit, OnDestroy {
  horizontalNavbar: boolean;
  rightNavbar: boolean;
  hiddenNavbar: boolean;
  isTa: boolean;
  navigation: any;
  mentorUser: MentorUser;
  showMonthlyCta: boolean;
  // Private
  private _unsubscribeAll: Subject<any>;

  constructor(
    private _fuseConfigService: FuseConfigService,
    private _fuseSidebarService: FuseSidebarService,
    private _mentorStore: Store<MentorState>,
    private coreService: CoreService,
    private dialog: MatDialog,
    private analyticsService: AuthAnalyticsService
  ) {
    this.navigation = navigation;

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to the config changes
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((settings) => {
        this.horizontalNavbar = settings.layout.navbar.position === 'top';
        this.rightNavbar = settings.layout.navbar.position === 'right';
        this.hiddenNavbar = settings.layout.navbar.hidden === true;
      });

    this._mentorStore
      .pipe(select('mentor'), takeUntil(this._unsubscribeAll))
      .subscribe((mentorData: MentorData) => {
        if (mentorData) {
          this.mentorUser = mentorData.mentorUser;
          if (this.mentorUser.roles.includes('ta')) {
            this.isTa = true;
          }
        }
      });
    this.viewMonthlyAchievements('automatic');
    this.showMonthlyAchievementsCta();
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebarOpen(key): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

  /**
   * Search
   *
   * @param value
   */
  search(value): void {
    // Do your search here...
  }

  logout() {
    this._mentorStore.dispatch(new Logout());
    location.reload();
  }

  onHelpClick() {
    const FRESHWORKS_MENTOR_GROUP_ID = 63000125598;
    (window as any).FreshworksWidget('open');
    (window as any).FreshworksWidget('prefill', 'ticketForm', {
      name: this.mentorUser.name,
      email: this.mentorUser.email,
      group_id: FRESHWORKS_MENTOR_GROUP_ID,
      subject: 'Teaching Assistant (TA) Feedback/Concern',
    });
    (window as any).FreshworksWidget('disable', 'ticketForm', [
      'name',
      'email',
      'subject',
    ]);
  }

  viewMonthlyAchievements(source: 'automatic' | 'ctaClick') {
    this.coreService
      .fetchMonthlyShareableCertificate(source)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        if (res.data?.show_popup) {
          const dialogRef = this.dialog.open(MonthlyShareablePopupComponent, {
            height: '620px',
            width: '520px',
            data: {
              certificateData: res.data,
              source,
            },
          });

          dialogRef.afterClosed().subscribe((response) => {
            this.sendPopupClosedEvent(response.data, source);
          });
        }
      });
  }

  showMonthlyAchievementsCta() {
    this.coreService
      .showMonthlyCertificteCta()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        if (res.data) {
          this.showMonthlyCta = res.data.show_cta;
        }
      });
  }

  sendPopupClosedEvent(data, source) {
    this.analyticsService.sendEvent({
      name: 'TA Monthly Achievement Sharing - Pop up Dismissed',
      category: 'TA Monthly Achievement Sharing',
      action: 'Pop up Dismissed',
      label: source,
      data: {
        user_id: data.user_id,
      },
    });
  }
}
