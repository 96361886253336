import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { BrowserService } from './browser.service';
import { getQueryParams } from '../utils/get-query-params.util';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import {
  ApiClient,
  Call,
  CallBuilder,
  CallMethod,
  Resource,
  URLS,
} from '@codingninjas/networking';
import { Observable, Subject } from 'rxjs';
import { isNullOrUndefined } from '@codingninjas/ninjas-utils/utils/blank-check.util';
import { ActivatedRoute, Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class ReferralTrackingService {
  referralApplied: boolean;
  referralData: any;
  isAffiliate = false;
  showCourses = false;
  isReferred = false;
  queryParams = {};
  isBrowser = false;

  constructor(
    @Inject(PLATFORM_ID) private _platformId: object,
    private browserService: BrowserService,
    private apiClient: ApiClient,
    private route: ActivatedRoute,
    private router: Router,
    @Optional() @Inject(REQUEST) private request: any
  ) {
    this.referralData = new Subject();
    this.isBrowser = isPlatformBrowser(this._platformId);
  }

  private static getReferralCodeExpiryDate() {
    const days = 15;
    const date = new Date();
    return new Date(date.getTime() + days * 24 * 60 * 60 * 1000);
  }

  public getReferralCode() {
    const queryParamsReferralCode = this.getQueryParamReferralCode();
    if (queryParamsReferralCode && queryParamsReferralCode['referralCode']) {
      return queryParamsReferralCode;
    }
    return this.getCookieReferralData();
  }

  updateReferredStatus() {
    if (this.getCookieReferralData()) {
      this.isReferred = true;
    } else {
      this.getReferredInfo().subscribe((resource) => {
        if (resource.isSuccessful()) {
          this.isReferred = resource.data.is_referred;
        }
      });
    }
  }
  getReferredInfo() {
    let marketingToken: string = this.browserService.getMarketingToken();
    if (isNullOrUndefined(marketingToken)) {
      marketingToken = '';
    }
    const call = new CallBuilder(
      CallMethod.Get,
      URLS.V3.USER_REFERRED_INFO
    ).setParam('marketing_token', marketingToken);
    const callBuild = call.build();
    return this.apiClient.enqueue<any>(callBuild);
  }
  getCookieReferralData() {
    try {
      return JSON.parse(this.browserService.getCookie('ninjas_referral_data'));
    } catch (e) {
      return null;
    }
  }

  getQueryParamReferralCode() {
    if (isPlatformBrowser(this._platformId)) {
      this.queryParams = getQueryParams();
    } else {
      if (this.request) {
        this.queryParams = this.request.query;
      }
    }
    let referralType;
    let referralCode;
    if (this.queryParams['referralCode'] || this.queryParams['referral_code']) {
      referralType = 'course';
      referralCode =
        this.queryParams['referralCode'] || this.queryParams['referral_code'];
    } else if (this.queryParams['scholarshipCode']) {
      referralType = 'scholarship';
      referralCode = this.queryParams['scholarshipCode'];
    }
    this.isAffiliate = this.queryParams['isAffiliate']
      ? this.queryParams['isAffiliate'] === 'true'
      : false;
    this.showCourses = this.queryParams['showCourses']
      ? this.queryParams['showCourses'] === 'true'
      : true;
    if (referralCode === undefined) {
      return null;
    }
    return {
      referralCode,
      referralType,
      appliedTime: new Date().getTime() / 1000,
    };
  }

  public setReferralCookie() {
    const referralCode = this.getQueryParamReferralCode();
    if (referralCode == null) {
      return;
    }
    const cookieExpiryDate =
      ReferralTrackingService.getReferralCodeExpiryDate();
    if (this.isBrowser) {
      this.browserService.setCookie(
        'ninjas_referral_data',
        JSON.stringify(referralCode),
        cookieExpiryDate
      );
    }
  }

  public applyReferralCode(
    referral_type,
    referral_code
  ): Observable<Resource<any>> {
    const body = {
      referral_type,
      referral_code,
    };

    const call: Call = new CallBuilder(
      CallMethod.Post,
      URLS.V2.APPLY_REFERRAL_CODE
    )
      .body(body)
      .build();
    return this.apiClient.enqueue<any>(call);
  }

  public referralCodeDetails(
    referral_code,
    referral_type
  ): Observable<Resource<any>> {
    const body = {
      referral_code,
      referral_type,
    };

    const call: Call = new CallBuilder(
      CallMethod.Post,
      URLS.V3.REFERRAL_CODE_DETAILS
    )
      .body(body)
      .build();
    return this.apiClient.enqueue<any>(call);
  }

  public isReferralApplied() {
    return this.referralApplied;
  }

  public setReferralApplied() {
    this.referralApplied = true;
    this.removeReferralCode();
  }

  removeReferralCode() {
    this.browserService.eraseCookie('ninjas_referral_data');
  }
}
