<div
  class="login-container"
  [ngClass]="{ 'auth-before-checkout-mod': showOptInCheckBox !== null }"
>
  <span *ngIf="continueWithNaukri" class="mt-4 zen-typo-body-large">
    Your Naukri and Coding Ninjas accounts are now linked. Continue logging in
    with Naukri to access your account.
  </span>
  <div class="social-login">
    <div *ngIf="OAuthError" class="oauth-error">{{ OAuthError }}</div>

    <div
      class="google-one-tap-wrapper"
      [ngStyle]="{
        height: oneTapVisible ? '189px' : '0px',
        display: oneTapVisible ? 'flex' : 'none'
      }"
    >
      <div id="google-one-tap-container"></div>
      <mat-spinner class="spinner_small"></mat-spinner>
    </div>

    <div *ngIf="!oneTapVisible && !continueWithNaukri">
      <button (click)="googleLogin()" class="login-btn">
        <img
          src="https://files.codingninjas.in/icon-google-24742.png"
          alt="google"
          width="20"
        />
        <span>Login with Google</span>
        <mat-spinner
          *ngIf="
            (isAuthenticating$ | async) &&
            (authProvider$ | async) === googleProvider
          "
          class="spinner_small"
        ></mat-spinner>
      </button>
    </div>
    <div *ngIf="!continueWithNaukri">
      <button (click)="fbLogin()" class="login-btn">
        <img
          src="https://files.codingninjas.in/icon-fb-24743.png"
          alt="facebook"
          width="20"
        />
        <span>Login with Facebook</span>
        <mat-spinner
          *ngIf="
            (isAuthenticating$ | async) &&
            (authProvider$ | async) === fbProvider
          "
          class="spinner_small"
        ></mat-spinner>
      </button>
    </div>
    <div>
      <button
        (click)="naukriLogin()"
        [disabled]="unverified"
        [class.disabled-button]="unverified"
        class="login-btn"
      >
        <img
          src="https://files.codingninjas.in/naukri-logo-1710924674.svg"
          alt="naukri"
          width="20"
        />
        <span>
          {{
            continueWithNaukri ? 'Continue with Naukri' : 'Login with Naukri'
          }}
        </span>
        <mat-spinner
          *ngIf="
            (isAuthenticating$ | async) &&
            (authProvider$ | async) === naukriProvider
          "
          class="spinner_small"
        ></mat-spinner>
      </button>
    </div>
    <div *ngIf="unverified" class="mat-error">
      We could not verify your email id. Please use different login method.
    </div>
  </div>

  <div *ngIf="!continueWithNaukri" class="ui horizontal divider">or</div>

  <div *ngIf="!continueWithNaukri" class="ninjas-login">
    <div style="text-align: center">use your email address</div>
    <section class="form-container">
      <div class="form-wrapper">
        <form [formGroup]="emailPhoneInputForm">
          <div class="field-row field">
            <mat-form-field color="accent">
              <input
                type="text"
                matInput
                formControlName="emailPhoneControl"
                placeholder="Email address"
                id="phoneEmail"
                required
                (input)="phoneEmailChange($event)"
              />
              <mat-error
                *ngIf="
                  emailPhoneInputForm
                    .get('emailPhoneControl')
                    .hasError('required')
                "
                >E-mail is required
              </mat-error>
              <mat-error
                *ngIf="
                  isEmail &&
                  emailPhoneInputForm
                    .get('emailPhoneControl')
                    .hasError('pattern')
                "
                >Please enter a valid email address
              </mat-error>
              <mat-error
                *ngIf="
                  emailPhoneInputForm
                    .get('emailPhoneControl')
                    .hasError('blocked')
                "
                >{{ errorMessage }}</mat-error
              >
            </mat-form-field>
          </div>
          <div class="buttons-row">
            <div class="submit-buttons">
              <button
                (click)="continuePressed()"
                type="submit"
                class="submit-button loading-button expand"
                [class.disabled]="emailPhoneInputForm.invalid"
                [class.sending]="isContinuePressed"
                [ngClass]="{
                  'round-continue-button': showOptInCheckBox !== null
                }"
              >
                {{ buttonText }}
              </button>
            </div>
          </div>
          <div class="opts-in-btn-container" *ngIf="showOptInCheckBox == true">
            <mat-checkbox formControlName="shouldSubToCnUpdatesControl">
            </mat-checkbox>
            <span class="opts-in-text">
              Send me updates from coding ninjas with interview preparation
              tips, coding news, tailored offers, new launch alerts. You can opt
              out any time.</span
            >
          </div>
        </form>
      </div>
    </section>
  </div>
</div>
