export class Doubt {
  id: number;
  doubtable_id: number;
  doubtable_type: string;
  doubtable_description: string;
  user_id: number;
  base_priority: number;
  language_used: string;
  course_module_name: string;
  workflow_state: string;
  last_activity_timestamp: number;
  last_accepted_mentor_email?: string;
  last_accepted_mentor_name?: string;
  title: string;
  subtitle: string;
  current_score: number;
  content: string;
  batch_id: number;
  course_version_id: number;
  opened_time: number;
  escalated_time: number;
  resolved_time: number;
  reopen_time: number;
  reopen_count: number;
  offering_id: number;
  created_at: number;
  resolution_time: number;
  user_has_active_session: boolean;
  user_is_online: boolean;
  user_name: string;
  last_closed_doubt_session_uuid: string;
  ta_with_active_doubt_session?: string;
  last_doubt_session_uuid: string;
  is_active: boolean;
  current_resolver_id: number;
  user_marked_resolved: boolean;
  already_submitted: boolean;
  user_rating: number;
  user_image: number;
  last_message_by: string;
  doubtable_name: string;
  video_paused_at: number;
  current_user_can_resolve: boolean;
  content_id: number;
  more_comments: boolean;
  reactions: number;
  is_liked_by_current_user: boolean;
  rating_reason: string;
  resolve_reason: string;
  escalate_reason: string;
  escalated_by_id: number;
  tag_list: string;
  reuse_appealed_by: string;
  is_escalated: boolean;
  no_of_c_messages: number;
  current_resolver: string;
  marked_for_reuse: boolean;
  doubtable_link: string;
  user_can_solve_doubt: boolean;
  course_image: string;
  course_name: string;
  being_solved_by_user: boolean;
  is_my_doubt: boolean;
  to_be_shifted: boolean;
  newly_added: boolean;
  category: string;
  content_icon_image_url: string;
  assigned_to_email: string;
  course_module_id: number;
  user_email: string;
  topic_name: string;
  student_rating: number;
  attempt_history: any[];
  ta_rating: number;
  ta_rating_reasons: string[];
  is_flagged_by_me: boolean;
  flagged_doubt_status: number;
  flagged_doubt_created_at: number;
  ta_communication_language: string;
  doubt_chat_token?: string;
  constructor() {}
}

export const DoubtStates = {
  RESOLVED: 'resolved',
  PENDING_ON_USER: 'pending_on_user',
  RESOLVED_REVIEW_PENDING: 'resolved_review_pending',
  ACTIVE: 'active',
  ASSIGNED: 'assigned',
  AVAILABLE: 'available',
  NEW: 'new',
  DEAD: 'dead',
};

export const DoubtRejectReasons = [
  'Incomplete Information',
  'Not comfortable in this topic',
  'Not comfortable with this problem',
  'Doubt not of this topic/problem',
  'Doubt other than course',
  'Some Administration Doubt',
  "Not comfortable in user's preferred language",
  'Other',
];

export const AlreadyAccpetDoubtRejectReasons = [];
